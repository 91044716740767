<template>
  <div class="add-ticket-modal">
    <div class="add-ticket-modal-card">
      <h3 class="add-new-section-heading">
        Add New package
        <img
          @click="$emit('cancelAdd')"
          src="@/assets/icons/cross-small.svg"
          alt=""
          class="close-drawer"
        />
      </h3>
      <div class="input_wrap my-10">
        <h4 for="smInput" class="">
          Package Title (e.g. From Mumbai or Group tour etc.)
        </h4>
        <input
          v-model="ticketHeading"
          class="sm_input --dark my-10"
          type="text"
          placeholder="You can add package title eg. From Mumbai"
          autocomplete="off"
        />
      </div>

      <div class="trek-location-box" v-if="addNewLocation">
        <div class="trek-location-box-inner" @click.stop>
          <select-location-form
            @locationAdded="addLocation"
            @cancel="cancelAddLocation"
            :noBtn="false"
            :noHeader="false"
            :onlyFullAddress="true"
            :includeTimePicker="false"
            :prefillLocation="editPickupObj?.data || pickupPoints[0] || null"
          ></select-location-form>
        </div>
      </div>
      <div class="input_wrap my-10">
        <h4 for="smInput" class="">Pickup Points (Optional)</h4>
        <div class="input-note">
          You can add multiple pickup points for same city
        </div>

        <div class="selected-locations">
          <h5 v-if="pickupPoints && pickupPoints.length">Added Locations</h5>
          <div
            class="selected-locations__item"
            v-for="(location, i) in pickupPoints"
            :key="i"
          >
            <span class="selected-location__item-address"
              ><strong>{{ `${location.address.full_address}` }}</strong> <br />
              <!-- {{ convertDateTime(location.datetime) }} -->
            </span>
            <div class="selected-locations-item-actions">
              <img
                class="selected-location__item-edit"
                @click.stop="editPickup(location, i)"
                src="@/assets/icons/edit.svg"
                alt=""
              />
              <img
                class="selected-location__item-cross"
                @click="removeLocation(i)"
                src="@/assets/icons/cross-small.svg"
                alt=""
              />
            </div>
          </div>

          <div
            class="cta-wrap --add-more-location"
            v-if="!addNewLocation && (pickupPoints || pickupPoints.length)"
            @click="addNewLocation = true"
          >
            <div class="pill_btn --hollow">
              {{
                pickupPoints.length
                  ? "Add another location"
                  : "Add Pickup point"
              }}
            </div>
          </div>
        </div>

        <div class="input_wrap my-10">
          <h3 for="smInput" class="">Available Seats</h3>
          <input
            v-model="seatsAvailable"
            class="sm_input --dark my-10"
            type="number"
            placeholder="Enter no. of seats available for this package"
            autocomplete="off"
          />
        </div>
        <div class="input_wrap my-10">
          <h3 for="smInput" class="">Base Price</h3>
          <input
            v-model="ticketPrice"
            class="sm_input --dark my-10"
            type="number"
            placeholder="Enter Price per Package"
            autocomplete="off"
          />
        </div>

        <!-- DISCOUNTS -->
        <!-- <div class="ticket-discount mt-20">
          <h4 for="smInput" class="">Ticket Discounts</h4>
          <div class="no-discounts my-10">No discounts added!</div>
          <div class="discounts">
            <div class="discounts-item" v-for="(item, i) in discounts" :key="i">
              <h4>{{ item.code }}</h4>
              <p>
                {{ item.selectedDiscountType === "amount" ? "Rs. " : ""
                }}{{ item.value }}
                {{ item.selectedDiscountType === "percent" ? "%" : "" }}
              </p>
            </div>
          </div>
          <div
            class="add-discount-btn pill_btn --small --hollow"
            v-if="openNewDiscount === false"
            @click="openNewDiscount = true"
          >
            Add discount
          </div>

          <div class="new-discount-card" v-if="openNewDiscount">
            <div class="input_wrap my-10">
              <label for="smInput" class="sm-label">Dicount title</label>
              <input
                v-model="newDiscount.title"
                class="sm_input --dark my-10"
                type="text"
                placeholder="Enter Price per Ticket"
                autocomplete="off"
              />
            </div>
            <div class="input_wrap my-10">
              <label for="smInput" class="sm-label"
                >Dicount description / note</label
              >
              <input
                v-model="newDiscount.description"
                class="sm_input --dark my-10"
                type="text"
                placeholder="Enter Price per Ticket"
                autocomplete="off"
              />
            </div>
            <div class="input_wrap my-10">
              <label for="smInput" class="sm-label">Dicount Type</label>
              <div class="discount-types my-10">
                <div
                  class="discount-types-option"
                  @click="newDiscount.selectedDiscountType = 'percent'"
                  :class="{
                    '--selected':
                      newDiscount.selectedDiscountType === 'percent',
                  }"
                >
                  Percent
                </div>
                <div
                  class="discount-types-option"
                  @click="newDiscount.selectedDiscountType = 'amount'"
                  :class="{
                    '--selected': newDiscount.selectedDiscountType === 'amount',
                  }"
                >
                  Fixed Amount
                </div>
              </div>
            </div>
            <div class="input_wrap my-10">
              <label for="smInput" class="sm-label">Discount Value</label>
              <input
                v-model="newDiscount.value"
                class="sm_input --dark mt-8"
                type="number"
                placeholder="Enter discount value"
                autocomplete="off"
              />
            </div>
            <div class="input_wrap my-10">
              <label for="smInput" class="sm-label">Coupen Code</label>
              <input
                v-model="newDiscount.code"
                class="sm_input --dark mt-8"
                type="text"
                placeholder="Enter Coupon code e.g FirstTicket100"
                autocomplete="off"
              />
            </div>
            <toggle-input
              class="my-15"
              :dark="true"
              :label="'Auto apply on booking'"
              @toggled="
                (e) => {
                  newDiscount.autoApply = e;
                }
              "
            ></toggle-input>
            <div class="split_btns">
              <div
                class="pill_btn --small"
                :class="{
                  '--disabled':
                    !newDiscount.selectedDiscountType ||
                    !newDiscount.value ||
                    !newDiscount.code,
                }"
                @click="addDiscount()"
              >
                Save discount
              </div>
              <div
                class="pill_btn --small --hollow --border-less"
                @click="openNewDiscount = false"
              >
                Cancel
              </div>
            </div>
          </div>
        </div> -->

        <div class="split_btns my-30">
          <div
            class="pill_btn --small"
            :class="{
              '--disabled': loadCreate || !ticketHeading || !ticketPrice,
            }"
            @click="createTicket()"
          >
            Save this ticket
          </div>
          <div
            class="pill_btn --small --hollow --border-less"
            @click="$emit('cancelAdd')"
          >
            Cancel
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectLocationForm from "@/components/inputs/SelectLocationForm.vue";
import computedMixin from "@/mixins/computedMixin";

export default {
  mixins: [computedMixin],
  components: {
    SelectLocationForm,
  },
  props: ["templateId", "editTicket"],
  data() {
    return {
      loadCreate: false,
      ticketHeading: null,
      newLocation: {},
      pickupPoints: [],
      addNewLocation: false,
      seatsAvailable: null,
      ticketPrice: null,
      openNewDiscount: false,
      newDiscount: {},
      discounts: [],
      editPickupObj: null,
    };
  },
  mounted() {
    this.prefill();
  },
  methods: {
    prefill() {
      if (this.editTicket) {
        this.ticketHeading = this.editTicket.ticket_heading;
        this.pickupPoints = this.editTicket.pickup_points;
        this.ticketPrice = this.editTicket.price_value;
        this.seatsAvailable = this.editTicket.seats_available;
        this.addNewLocation = false;
      }
    },
    cancelAddLocation() {
      this.addNewLocation = false;
      this.editPickupObj = false;
    },
    addLocation(location) {
      this.addNewLocation = false;
      if (this.editPickupObj) {
        this.pickupPoints.splice(this.editPickupObj.index, 1);
        this.pickupPoints.splice(this.editPickupObj.index, 0, location);
        this.editPickupObj = null;
      } else {
        this.pickupPoints.push(location);
      }
      // console.log(this.pickupPoints);
    },
    removeLocation(index) {
      this.pickupPoints.splice(index, 1);
    },
    editPickup(data, i) {
      this.editPickupObj = {
        index: i,
        data: data,
      };
      this.addNewLocation = true;
    },
    convertDateTime(d) {
      const date = new Date(d); // Replace this with your desired date

      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      };

      const formattedDate = date.toLocaleDateString("en-US", options);
      return formattedDate;
    },
    // addDiscount() {
    //   this.discounts.push(this.newDiscount);
    //   (this.newDiscount = {}), (this.openNewDiscount = false);
    // },
    async createTicket() {
      this.loadCreate = true;
      const ticket = {
        // companyId: this.company._id,
        // templateId: this.templateId,

        ticket_heading: this.ticketHeading,
        pickup_points: this.pickupPoints,
        seats_available: this.seatsAvailable,
        price_value: this.ticketPrice,
      };
      if (this.editTicket && this.editTicket._id) {
        ticket._id = this.editTicket._id;
      }
      // if (this.editTicket) {
      //   data.ticketId = this.editTicket._id;
      // }
      // const ticket = await TicketSetters.createTicket(data);
      this.$emit("ticketAdded", ticket);
      this.loadCreate = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.add-ticket-modal {
  padding: $sp-md;
}

.add-new-section-heading {
  font-size: $txt-lg;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $sp-xl;
  .close-drawer {
    width: $icon-w-md;
  }
}

.trek-location-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: $primary-bg-color-backdrop;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $sp-md;
  box-sizing: border-box;
}
.trek-location-box-inner {
  padding: $sp-sm;
  border-radius: $br-sm;
  border: 1px dashed $tertiary-text-color;
  background-color: $primary-bg-color;
  margin: $sp-md 0 $sp-lg;
}

.selected-locations {
  margin: $sp-md 0;
  .selected-locations__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $sp-md;
    background-color: $primary-bg-color;
    padding: $sp-sm;
    border-radius: $br-sm;
    margin: $sp-sm 0;
    .selected-location__item-address {
      font-size: $txt-sm;
      white-space: pre-wrap;
    }
    .selected-locations-item-actions {
      display: flex;
      align-items: center;
      gap: $sp-md;
      .selected-location__item-edit {
        width: $icon-w-xs;
        cursor: pointer;
      }
      .selected-location__item-cross {
        width: $icon-w-sm;
        cursor: pointer;
      }
    }
  }
}
.--add-more-location {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: $sp-sm;

  .pill_btn {
    font-size: $txt-xs;
  }
}

.add-discount-btn {
  margin: auto;
}

.no-discounts {
  font-size: $sp-md;
}

.discounts {
  display: flex;
  flex-direction: column;
  gap: $sp-sm;

  .discounts-item {
    background-color: $primary-bg-color;
    border-radius: $br-sm;
    padding: $sp-sm;
    display: flex;
    align-items: center;
    gap: $sp-lg;
    font-size: $txt-sm;
  }
}

.ticket-discount {
  .new-discount-card {
    margin-top: $sp-sm;
    border: 1px dashed $tertiary-text-color;
    border-radius: $br-sm;
    padding: $sp-sm;
  }
}

.discount-types {
  display: flex;
  align-items: center;
  gap: $sp-sm;

  .discount-types-option {
    padding: $sp-sm $sp-md;
    border-radius: $br-sm;
    font-size: $txt-xs;
    border: 1px dashed $tertiary-text-color;
    background-color: $secondary-bg-color-hover;
    color: $primary-text-color;
    cursor: pointer;

    &:hover {
      border: 1px dashed $secondary-text-color;
      color: $secondary-text-color;
    }

    &.--selected {
      border: 1px dashed $primary-text-color;
      color: $primary-text-color;
      background-color: $primary-bg-color;
    }
  }
}
</style>
