<template>
  <create-card>
    <template v-slot:header>
      <!-- content for the header slot -->
      <h2 class="card-header">Upload Photos</h2>
      <p class="card-header-company-name">{{ trek?.trek_name }}</p>
    </template>

    <!-- <p class="section-note">This profile will be visible to your customers</p> -->
    <div class="input_wrap">
      <label for="" class="sm-label">Cover YouTube video (optional)</label>

      <input
        v-model="youtubeLink"
        class="sm_input mt-10"
        type="text"
        placeholder="Enter youtube link to your cover video"
        autocomplete="off"
      />
      <small class="input-note --warning"
        >This video will appear instead of cover image</small
      >
      <iframe
        v-if="youtubeLink"
        width="100%"
        height="280"
        :src="convertToEmbedLink(youtubeLink)"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </div>
    <div class="hr"></div>
    <div class="input_wrap">
      <div class="file_input_dumy" @click="openUppyPhotos = true">
        <img class="file_input_icon" src="@/assets/icons/image.svg" alt="" />
        <span>Upload photos</span>
      </div>
      <UppyImgInput
        v-show="openUppyPhotos"
        @fileUploaded="fileUploadedPhotos"
        @close="openUppyPhotos = false"
        :filesLimit="10"
      />
      <PhotosList
        v-if="photos && photos.length"
        :photos="photos"
        :cover="coverPhoto"
        @photoDeleted="deletePhoto"
        @setCover="setCover"
      ></PhotosList>
    </div>

    <div class="cta-wrap">
      <div class="pill_btn --hollow --left-icon" @click="back()">
        <img class="back-btn-icon" src="@/assets/icons/angle-left.svg" alt="" />
        Back
      </div>
      <div
        class="pill_btn --right-icon"
        @click="updatePhotos()"
        :class="{ '--disabled': loadingUpdate }"
      >
        Next <img src="@/assets/icons/angle-right.svg" alt="" />
      </div>
    </div>
  </create-card>
</template>

<script>
import CreateCard from "@/components/layouts/CreateCard.vue";
import UppyImgInput from "@/components/inputs/UppyInput.vue";
import PhotosList from "@/components/common/PhotosList.vue";
import GetterService from "@/services/trek/GetterService";
import TrekSetters from "@/services/trek/SetterServices";
import computedMixin from "@/mixins/computedMixin";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  mixins: [computedMixin],
  components: { CreateCard, UppyImgInput, PhotosList },
  data() {
    return {
      loadingUpdate: false,
      openUppyPhotos: false,
      photos: [],
      coverPhoto: null,
      youtubeLink: null,
    };
  },
  computed: {
    companyId() {
      return this.$route.params.companyId;
    },
    trekId() {
      return this.$route.params.trekId;
    },
    templateId() {
      return this.$route.query.tid;
    },
    currentRouteMeta() {
      return this.$route.meta;
    },
  },
  mounted() {
    if (this.trekId || this.templateId) {
      this.prefill();
    }
  },
  methods: {
    back() {
      this.$router.push({
        name: this.currentRouteMeta.preStep,
        params: { companyId: this.companyId },
        query: { trekId: this.trekId },
      });
    },
    async prefill() {
      let trek;
      if (this.templateId) {
        trek = await GetterService.templateById(this.templateId);
      } else {
        trek = await GetterService.publicTrekById(this.trekId);
      }
      this.trek = trek.data.trek;
      if (this.trek.photos) {
        this.photos = this.trek.photos;
      }
      if (this.trek.cover_pic) {
        this.coverPhoto = this.trek.cover_pic;
      }
      if (this.trek.cover_video) {
        this.youtubeLink = this.trek.cover_video;
      }
    },

    fileUploadedPhotos(e) {
      setTimeout(() => {
        this.photos.push(e.name);
      }, 2000);
    },
    setCover(cover) {
      this.coverPhoto = cover;
    },
    deletePhoto(photo) {
      const index = this.photos.indexOf(photo);
      this.photos.splice(index, 1);
      if (this.coverPhoto == photo) {
        this.coverPhoto = this.photos[0] || "";
      }
    },
    async updatePhotos() {
      try {
        this.loadingUpdate = true;
        const data = {
          companyId: this.company._id,
          trekId: this.trekId,
          coverPhoto: this.coverPhoto || this.photos[0],
          coverVideo: this.youtubeLink
            ? this.convertToEmbedLink(this.youtubeLink)
            : null,
          photos: this.photos,
        };
        const trek = await TrekSetters.updateTrekSection(data, "photos");
        this.$router.push({
          name: "CreateTrekItinerary",
          params: { companyId: this.companyId, trekId: trek._id },
          query: { tid: this.templateId },
        });
        this.loadingUpdate = false;
      } catch (error) {
        toast.error("Something went wrong, please try again!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
        this.loadingUpdate = false;
      }
    },

    extractYouTubeVideoID(url) {
      const regex =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      const match = url.match(regex);
      return match ? match[1] : null;
    },

    convertToEmbedLink(url) {
      const videoID = this.extractYouTubeVideoID(url);
      if (videoID) {
        return `https://www.youtube.com/embed/${videoID}`;
      } else {
        return null; // Return null if the video ID can't be extracted
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.--name-input {
  font-size: $txt-sm;
  padding: $sp-md;
}
.card-header-company-name {
  margin-bottom: 36px;
}

.card-header,
.card-header-company-name {
  text-align: left;
}
.--logo-input-wrap {
  display: flex;
  align-items: center;
  gap: $sp-md;
}
.cta-wrap {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media only screen and (min-width: 600px) {
  .--name-input {
    font-size: $txt-md;
    padding: $sp-md;
  }
}
</style>
