<template>
  <div class="uppy-container" @click="$emit('close')">
    <Dashboard
      @click.stop
      :uppy="uppy"
      :plugins="['DragDrop']"
      :class="'dashboard-class'"
    />
  </div>
</template>

<script>
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import { Dashboard } from "@uppy/vue";
import ImageEditor from "@uppy/image-editor";
import constants from "@/constants";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/image-editor/dist/style.min.css";
import Compressor from "@uppy/compressor";
import computedMixin from "@/mixins/computedMixin";

import Axios from "axios";

export default {
  mixins: [computedMixin],
  name: "UppyImgInput",
  props: ["filesLimit"],
  components: {
    Dashboard,
  },
  computed: {
    currentPlace() {
      if (this.currentCityId) {
        return this.currentCity;
      } else if (this.currentSpotId) {
        return this.currentSpot;
      } else if (this.currentTTDId) {
        return this.currentTTD;
      } else {
        return 0;
      }
    },
  },
  data() {
    return {
      uploadedFiles: [],
      uppy: new Uppy({
        autoProceed: false,
        restrictions: {
          maxNumberOfFiles: this.filesLimit,
          maxFileSize: 1000000,
        },
      }).use(AwsS3, {
        limit: 10,
        getUploadParameters: (file) => {
          const { name, type } = file.data;
          // let imageName = `${name}-${this.currentPlace._id}-${new Date()}`;
          const payload = {
            "Content-Type": "application/json",
            name,
            type,
            placeId: this.currentPlace._id,
          };
          return Axios.post(`${constants.apiUrl}/images/add`, payload).then(
            (response) => {
              // Return an object in the correct shape to AWS bucket
              this.passUploadedFiles(response.data.imgName);
              return {
                url: response.data.url,
                method: "PUT",
              };
            }
          );
        },
      }),
    };
  },
  mounted() {
    // this.uppy.on("upload-success", (file) => {
    //   this.$emit("fileUploaded", file.data);
    // });

    this.uppy.on("upload-error", (file, error) => {
      console.error(error);
    });

    this.uppy.use(Compressor, {
      quality: 0.7,
      targetSize: 500,
    });
  },
  methods: {
    setUploadedFiles(callback) {
      this.uploadedFiles = callback(this.uploadedFiles);
    },
    passUploadedFiles(data) {
      this.$emit("fileUploaded", { name: data });
    },
  },
};
</script>

<style lang="scss">
.uppy-container {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #0000002f;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  .dashboard-class {
    margin: 15px;
    width: calc(100% - 30px);
    .uppy-Dashboard-inner {
      background-color: #252936 !important;
      width: 100% !important;
      height: 300px !important;
      box-shadow: 0 0 25px 5px #000;
      border: none;

      .uppy-Dashboard-AddFiles {
        border: none;
      }
      .uppy-DashboardContent-bar {
        background-color: #252936;
        color: #fff;
      }
      .uppy-StatusBar-actions,
      .uppy-StatusBar,
      .uppy-StatusBar-statusPrimary {
        background-color: #252936;
        color: #fff !important;
      }
      .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
        background-color: #6082f1;
      }
      .uppy-DashboardContent-addMore,
      .uppy-DashboardContent-back {
        border: 1px solid #ffffff4a;
        color: #fff;
      }

      .uppy-Dashboard-AddFiles {
        background-color: #252936be;

        button {
          color: #6082f1;
        }
      }

      .uppy-Dashboard-Item-fileInfo {
        color: #fff;
      }
      .uppy-Dashboard-AddFiles-title {
        color: #fff !important;
      }
    }
  }
}
@media only screen and (min-width: 940px) {
  .uppy-container {
    .dashboard-class {
      width: 50%;
    }
  }
}
</style>
