import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/scss/global.scss";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.VUE_APP_TRAVV_FIREBASE_apiKey,
  authDomain: process.env.VUE_APP_TRAVV_FIREBASE_authDomain,
  projectId: process.env.VUE_APP_TRAVV_FIREBASE_projectId,
  storageBucket: process.env.VUE_APP_TRAVV_FIREBASE_storageBucket,
  messagingSenderId: process.env.VUE_APP_TRAVV_FIREBASE_messagingSenderId,
  appId: process.env.VUE_APP_TRAVV_FIREBASE_appId,
  measurementId: process.env.VUE_APP_TRAVV_FIREBASE_measurementId,
};

// Initialize Firebase
initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

if (process.env.NODE_ENV === "production") {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = function () {};
}

console.log("Testing...");

createApp(App).use(store).use(router).mount("#app");
