<template>
  <create-card v-if="trek">
    <template v-slot:header>
      <!-- content for the header slot -->
      <h2 class="card-header">Event Itinerary</h2>
      <p class="card-header-company-name">{{ trek?.trek_name }}</p>
    </template>

    <!-- <p class="section-note">This profile will be visible to your customers</p> -->

    <div class="trek-itinerary-wrap">
      <!-- <div class="no-of-days-input-wrap input_wrap my-15">
        <div for="smInput" class="sm-label my-10">No of days:</div>
        <input
          v-model="noOfDays"
          class="no-of-days-input"
          disabled
          type="number"
        />
        <img
          src="@/assets/icons/add.svg"
          alt=""
          class="add-day-plus-icon"
          @click="noOfDays++"
        />
      </div> -->
      <div class="trek-itinerary-card">
        <itinerary-card
          :noOfDays="noOfDays"
          :itinerary="itinerary"
          @itineraryChanged="updatedItinerary"
          @dayRemoved="dayRemoved"
        ></itinerary-card>
      </div>
    </div>

    <div class="cta-wrap">
      <div class="pill_btn --hollow --left-icon" @click="back()">
        <img class="back-btn-icon" src="@/assets/icons/angle-left.svg" alt="" />
        Back
      </div>
      <div
        class="pill_btn --right-icon"
        @click="updateitinerary()"
        :class="{ '--disabled': loadingUpdate }"
      >
        Next <img src="@/assets/icons/angle-right.svg" alt="" />
      </div>
    </div>
  </create-card>
</template>

<script>
import CreateCard from "@/components/layouts/CreateCard.vue";
import ItineraryCard from "@/components/create/trek/ItineraryCard.vue";
import GetterService from "@/services/trek/GetterService";
import TrekSetters from "@/services/trek/SetterServices";
import computedMixin from "@/mixins/computedMixin";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  mixins: [computedMixin],
  components: { CreateCard, ItineraryCard },
  data() {
    return {
      loadingUpdate: false,
      noOfDays: 1,
      itinerary: [],
    };
  },
  computed: {
    companyUsername() {
      return this.$route.params.companyUsername;
    },
    trekId() {
      return this.$route.params.trekId;
    },
    templateId() {
      return this.$route.query.tid;
    },

    currentRouteMeta() {
      return this.$route.meta;
    },
  },
  mounted() {
    if (this.trekId || this.templateId) {
      this.prefill();
    }
  },
  methods: {
    async prefill() {
      let trek;
      if (this.templateId) {
        trek = await GetterService.templateById(this.templateId);
      } else {
        trek = await GetterService.publicTrekById(this.trekId);
      }
      this.trek = trek.data.trek;
      if (this.trek.itinerary.length) {
        this.itinerary = this.trek.itinerary;
      }
    },
    back() {
      this.$router.push({
        name: this.currentRouteMeta.preStep,
        params: { companyId: this.companyUsername },
      });
    },
    // dayRemoved(e) {
    //   console.log(e);
    //   this.noOfDays = e;
    // },
    updatedItinerary(itinerary) {
      this.itinerary = itinerary;
    },

    async updateitinerary() {
      try {
        this.loadingUpdate = true;
        const data = {
          companyId: this.company._id,
          trekId: this.trekId,
          itinerary: this.itinerary,
        };
        const trek = await TrekSetters.updateTrekSection(data, "itinerary");
        this.$router.push({
          name: "CreateInstructions",
          params: { companyId: this.companyId, trekId: trek._id },
          query: { tid: this.templateId },
        });
        this.loadingUpdate = false;
      } catch (error) {
        toast.error("Something went wrong, please try again!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
        this.loadingUpdate = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.card-header-company-name {
  margin-bottom: 36px;
}

.card-header,
.card-header-company-name {
  text-align: left;
}
.--logo-input-wrap {
  display: flex;
  align-items: center;
  gap: $sp-md;
}
.cta-wrap {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.no-of-days-input-wrap {
  display: flex;
  align-items: center;
  gap: $sp-sm;
}
.no-of-days-input {
  display: inline-block;
  width: 60px;
  text-align: center;
  padding: $sp-xs;
}
.add-day-plus-icon {
  cursor: pointer;
  opacity: 0.5;
  width: $icon-w-sm;

  &:hover {
    opacity: 1;
  }
}
.trek-itinerary-wrap {
  .trek-itinerary-card {
    background-color: $secondary-bg-color;
    border-radius: $br-sm;
    padding: $sp-xs $sp-md;
  }
}
@media only screen and (min-width: 600px) {
  .trek-itinerary-wrap {
    .trek-itinerary-card {
      background-color: $secondary-bg-color;
      border-radius: $br-sm;
      padding: $sp-xs $sp-lg;
    }
  }
}
</style>
