<template>
  <create-card>
    <template v-slot:header>
      <!-- content for the header slot -->
      <h2 class="card-header">
        {{ companyId ? "Company Settings" : "Let's Create a company!" }}
      </h2>
    </template>

    <div class="input_wrap my-20">
      <label for="smInput" class="sm-label">Company name</label>
      <input
        v-model="createCompanyName"
        class="--name-input sm_input my-10"
        type="text"
        placeholder="Enter your company name"
        autocomplete="off"
      />
    </div>

    <div class="input_wrap my-20">
      <label for="smInput" class="sm-label">Username</label>
      <div class="input_with-btn-wrap">
        <input
          v-model="createCompanyUsername"
          class="--name-input sm_input my-10"
          type="text"
          placeholder="Enter your company name"
          pattern="[a-z0-9_]{3,16}"
          autocomplete="off"
          @input="validateUsername()"
        />
        <div
          class="check-username-btn pill_btn --small"
          @click="checkUsername()"
        >
          check
        </div>
      </div>
      <p class="input-note --error" v-if="!isValidUsername">
        Username should not contain any spaces, special characters or capital
        letters! & should be between 3-16 characters
      </p>
      <p
        class="input-note --success"
        v-else-if="
          usernameAvailabele !== null &&
          usernameAvailabele &&
          createCompanyUsername
        "
      >
        Username available
      </p>

      <p
        class="input-note --error"
        v-if="usernameAvailabele !== null && !usernameAvailabele"
      >
        Username not available
      </p>
      <p class="input-note">People will find you with this username</p>
    </div>

    <div class="cta-wrap">
      <div
        class="pill_btn --hollow --left-icon"
        v-if="companyId"
        @click="back()"
      >
        <img class="back-btn-icon" src="@/assets/icons/angle-left.svg" alt="" />
        Back
      </div>
      <div
        v-if="companyId"
        class="pill_btn --right-icon"
        @click="updateCompany()"
        :class="{
          '--disabled':
            loadingCreate == true ||
            !createCompanyName ||
            !createCompanyUsername ||
            !usernameAvailabele,
        }"
      >
        Save
        <img src="@/assets/icons/angle-right.svg" alt="" />
      </div>
      <div
        v-else
        class="pill_btn --right-icon"
        @click="createCompany()"
        :class="{
          '--disabled':
            loadingCreate == true ||
            !createCompanyName ||
            !createCompanyUsername,
        }"
      >
        Create
        <img src="@/assets/icons/angle-right.svg" alt="" />
      </div>
    </div>
  </create-card>
</template>

<script>
import CreateCard from "@/components/layouts/CreateCard.vue";
import computedMixin from "@/mixins/computedMixin";
import GetterServices from "@/services/company/GetterServices";
import CompanySetters from "@/services/company/SetterServices";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  mixins: [computedMixin],
  components: { CreateCard },
  data() {
    return {
      loadingCreate: false,
      createCompanyName: null,
      createCompanyUsername: null,
      usernameAvailabele: null,
      isValidUsername: true,
    };
  },
  computed: {
    companyId() {
      return this.$route.query.companyId;
    },
  },
  watch: {
    // companyUsername() {
    //   if (
    //     this.companyUsername &&
    //     this.company &&
    //     this.companyUsername != this.company?.company_username
    //   ) {
    //     this.checkUsername();
    //   } else {
    //     this.usernameAvailabele = true;
    //   }
    // },
  },
  mounted() {
    if (this.companyId) {
      this.prefill();
    }
  },
  methods: {
    async prefill() {
      let company = await GetterServices.companyById(this.companyId);
      this.company = company.data;
      this.createCompanyName = this.company?.company_name;
      this.createCompanyUsername = this.company?.company_username;
    },
    back() {
      if (!this.companyId) {
        this.$router.push({ name: "UserDashboard" });
      } else {
        this.$router.push({
          name: "CompanyDashboard",
          params: { companyId: this.company?._id },
        });
      }
    },
    validateUsername() {
      // Remove special characters and spaces
      const regex = /^[a-zA-Z0-9]+$/;
      this.isValidUsername = regex.test(this.createCompanyUsername);
      this.createCompanyUsername = this.createCompanyUsername
        .replace(/[^a-zA-Z0-9]/g, "")
        .toLowerCase();
    },
    async checkUsername() {
      const regex = /^[a-z0-9_]{3,16}$/;
      this.isValidUsername = regex.test(this.createCompanyUsername);

      if (
        this.isValidUsername &&
        this.createCompanyUsername &&
        this.createCompanyUsername != this.company?.company_username
      ) {
        let data = await GetterServices.checkUsername(
          this.createCompanyUsername,
          this.companyId
        );
        this.usernameAvailabele = data.data.available;
      } else {
        this.usernameAvailabele = true;
      }
    },
    async createCompany() {
      try {
        await this.checkUsername();
        this.loadingCreate = true;
        const data = {
          companyName: this.createCompanyName,
          companyUsername: this.createCompanyUsername,
        };
        if (this.usernameAvailabele) {
          const company = await CompanySetters.createCompany(data);
          if (company?._id) {
            this.$router.push({
              name: "CreateContact",
              params: { companyId: company._id },
            });
          }
        } else {
          this.loadingCreate = false;
        }
      } catch (error) {
        toast.error("Something went wrong, please try again!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
        this.loadingCreate = false;
      }
    },
    async updateCompany() {
      try {
        await this.checkUsername();
        this.loadingCreate = true;
        const data = {
          companyId: this.company._id,
          companyName: this.createCompanyName,
          companyUsername: this.createCompanyUsername,
        };
        if (this.usernameAvailabele) {
          const company = await CompanySetters.createCompany(data);
          if (company?._id) {
            this.$router.push({
              name: "CreateContact",
              params: { companyId: company._id },
            });
          }
        } else {
          this.loadingCreate = false;
        }
      } catch (error) {
        toast.error("Something went wrong, please try again!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
        this.loadingCreate = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

input:invalid {
  border-color: red;
}

.--name-input {
  font-size: $txt-sm;
  padding: $sp-md;
}
.card-header {
  text-align: left;
  margin-bottom: 36px;
}
.cta-wrap {
  margin-bottom: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.input_with-btn-wrap {
  position: relative;
  .check-username-btn {
    position: absolute;
    right: $sp-sm;
    top: $sp-md;
  }
}
@media only screen and (min-width: 600px) {
  .--name-input {
    font-size: $txt-md;
    padding: $sp-md;
  }
}
</style>
