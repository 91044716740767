<template>
  <datepicker
    class="datepicker-input"
    v-model="date"
    input-class-name="dp-input-styles"
    dark
    multi-dates
    :partial-range="false"
    placeholder="Select all available dates"
    :enable-time-picker="false"
    @update:model-value="selectDates"
    @update-month-year="handleMonthYear"
    @cleared="date = []"
    :position="'left'"
    :month-change-on-scroll="false"
    @date-update="dateClicked"
    auto-apply="true"
    ref="datepicker"
  >
    <!-- Custom Left Sidebar for "Select All Dates" Button -->
    <template #left-sidebar>
      <div class="sidebar">
        <button @click="selectAllDates">Select complete month</button>
      </div>
    </template>
    <template #action-preview="{}"></template>
  </datepicker>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { startOfMonth, endOfMonth, eachDayOfInterval } from "date-fns";

export default {
  props: ["selectedDates"],
  components: { Datepicker },
  data() {
    return {
      date: [],
      currentMonth: new Date().getMonth(), // Current month
      currentYear: new Date().getFullYear(), // Current year
    };
  },
  watch: {
    selectedDates: {
      handler(newValue) {
        if (this.selectedDates?.length) {
          this.date = this.selectedDates;
        }
      },
      deep: true,
    },
  },
  methods: {
    selectDates() {
      this.$refs.datepicker.openMenu();
      this.$refs.datepicker.setMonthYear(1, 2024);
      this.$emit("datesSelected", this.date);
    },
    handleMonthYear({ month, year }) {
      this.currentMonth = month;
      this.currentYear = year;
    },
    selectAllDates() {
      // Calculate the start and end of the current month
      const start = startOfMonth(new Date(this.currentYear, this.currentMonth));
      const end = endOfMonth(new Date(this.currentYear, this.currentMonth));

      // Get all dates within the interval of the current month
      const allDatesInMonth = eachDayOfInterval({ start, end });

      // Combine selected dates and the new dates
      const uniqueDates = new Set([
        ...this.date,
        ...allDatesInMonth.map((d) => d.toISOString()), // Avoid duplicates
      ]);

      // Update the datepicker model with selected dates for the current month
      this.date = Array.from(uniqueDates).map((d) => new Date(d));
      this.$emit("datesSelected", this.date); // Emit the updated dates
    },
  },
};
</script>

<style>
.dp__theme_dark {
  --dp-background-color: #252936;
  --dp-text-color: #ffffff;
  --dp-hover-color: #b6bbc8;
  --dp-hover-text-color: #d3d3de;
  --dp-hover-icon-color: #d3d3de;
  --dp-primary-color: #7e9cff;
  --dp-primary-text-color: #ffffff;
  --dp-secondary-color: #d8d8d8;
  --dp-border-color: #7e9cff00;
  --dp-menu-border-color: #7e9cff94;
  --dp-border-color-hover: #7e9cff;
  --dp-disabled-color: #737373;
  --dp-scroll-bar-background: #212121;
  --dp-scroll-bar-color: #484848;
  --dp-success-color: #00701a;
  --dp-success-color-disabled: #428f59;
  --dp-icon-color: #e0e0e0;
  --dp-danger-color: #e53935;
  --dp-highlight-color: rgba(0, 92, 178, 0.2);
}

.datepicker-input {
  width: 100%;
  border-radius: 10px;
}
.dp-input-styles {
  height: 40px !important;
  border-radius: 10px;
  border: 1px solid #7e9cff99;
}

.sidebar {
  padding: 0;
}

.sidebar button {
  display: block;
  margin: 5px 0;
  padding: 12px 8px;
  background-color: #7e9cff00;
  border: 1px solid #5c78db;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
}

.sidebar button:hover {
  background-color: #5c78db;
}
</style>
