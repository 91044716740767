import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
} from "vue-router";

import { getAuth, onAuthStateChanged } from "firebase/auth";

// Auth Views ____________________________________________________________________
import LoginView from "../components/auth/LoginCard.vue";
import SignupView from "../components/auth/RegisterCard.vue";
import AuthView from "../views/auth/AuthView.vue";

// User Views ____________________________________________________________________
import UserDashboard from "../views/dashboards/UserDashboard.vue";

// COMPANY VIEWS _________________________________________________________________
import CompanyLayout from "../views/company/layout/CompanyLayout.vue";

// Create Company views ----------------------------------------------------------
import CreateView from "../views/company/create/CreateView.vue";
import CreateNewCompany from "../views/company/create/CreateNewCompany.vue";
import CreateContact from "../views/company/create/CreateContact.vue";
// import CreateLocations from "../views/company/create/CreateLocations.vue";
import CreateProfile from "../views/company/create/CreateProfile.vue";

// COMPANY DASHBOARD ------------------------------------------------------------
import CompanyDashboard from "../views/dashboards/CompanyDashboard.vue";

// TREK VIEWS _____________________________________________________________

// CREATE TREK VIEWs -----------------------------------------------------------
import TrekCreateView from "../views/trek/create/TrekCreateView.vue";
import CreateOptions from "../views/trek/create/CreateOptions.vue";
import CreateTrekInfo from "../views/trek/create/CreateTrekInfo.vue";
import CreateTrekPhotos from "../views/trek/create/CreateTrekPhotos.vue";
import CreateTrekItinerary from "../views/trek/create/CreateTrekItinerary.vue";
import CreateInstructions from "../views/trek/create/CreateInstructions.vue";
import CreateTrekEvent from "../views/trek/create/CreateTrekEvent.vue";

// CREATE CATEGORY VIEW ---------------------------------------------------------
import CategoryCreateView from "@/views/categories/create/CategoryCreateView.vue";
import CreateCategory from "../views/categories/create/CreateCategory.vue";

// TREK DASHBOARD VIEW ---------------------------------------------------------
import TrekDashboard from "@/views/dashboards/TrekDashboard.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "LoginView",
    component: LoginView,
  },
  // AUTH ROUTES --------------------------------
  {
    path: "/auth",
    name: "AuthView",
    component: AuthView,
    children: [
      {
        path: "/login",
        name: "LoginView",
        component: LoginView,
      },
      {
        path: "/signup",
        name: "SignupView",
        component: SignupView,
      },
    ],
  },
  // USER DASHBOARD ROUTES ----------------------
  {
    path: "/user",
    name: "UserDashboard",
    component: UserDashboard,
    meta: {
      authRequired: true,
    },
  },
  // Create Company Routes -----------------------
  {
    path: "/company/create",
    name: "CreateView",
    component: CreateView,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "new",
        name: "CreateNewCompany",
        component: CreateNewCompany,
      },
      {
        path: ":companyId/contact",
        name: "CreateContact",
        component: CreateContact,
      },
      // {
      //   path: ":companyId/locations",
      //   name: "CreateLocations",
      //   component: CreateLocations,
      // },
      {
        path: ":companyId/profile",
        name: "CreateProfile",
        component: CreateProfile,
      },
    ],
  },
  // COMPANY VIEW ROUTES (ALL DASHBOARDS & CREATE WHICH INCLUDES COMPANY DATA) --------------------
  {
    path: "/:companyId",
    component: CompanyLayout,
    children: [
      // DASHBOARDS -----------------------------------
      {
        path: "dashboard",
        name: "CompanyDashboard",
        component: CompanyDashboard,
      },
      {
        path: "e/:trekId" /* e for event/trek*/,
        name: "TrekDashboard",
        component: TrekDashboard,
      },
      // DASHBOARDS -----------------------------------

      // CREATE TREK ROUTES -----------------------------------
      {
        path: "trek/create",
        name: "TrekCreateView",
        component: TrekCreateView,
        meta: {
          authRequired: true,
        },
        children: [
          {
            path: "create-options",
            name: "CreateOptions",
            component: CreateOptions,
          },
          {
            path: "info",
            name: "CreateTrekInfo",
            component: CreateTrekInfo,
            meta: {
              stepNo: 1,
              preStep: "EventsDashboard",
              nextStep: "CreateTrekPhotos",
            },
          },
          {
            path: ":trekId/photos",
            name: "CreateTrekPhotos",
            component: CreateTrekPhotos,
            meta: {
              stepNo: 2,
              preStep: "CreateTrekInfo",
              nextStep: "CreateTrekItinerary",
            },
          },
          {
            path: ":trekId/itinerary",
            name: "CreateTrekItinerary",
            component: CreateTrekItinerary,
            meta: {
              stepNo: 3,
              preStep: "CreateTrekPhotos",
              nextStep: "CreateInstructions",
            },
          },
          {
            path: ":trekId/instructions",
            name: "CreateInstructions",
            component: CreateInstructions,
            meta: {
              stepNo: 4,
              preStep: "CreateTrekItinerary",
              nextStep: "CreateTrekEvent",
            },
          },
          {
            path: ":trekId/event",
            name: "CreateTrekEvent",
            component: CreateTrekEvent,
            meta: {
              stepNo: 5,
              preStep: "CreateInstructions",
              nextStep: "",
            },
          },
        ],
      },
      // CREATE TREK ROUTES -----------------------------------
      // CREATE CATEGORY ROUTES -----------------------------------
      {
        path: "category/create",
        name: "CreateCategoryView",
        component: CategoryCreateView,
        meta: {
          authRequired: true,
        },
        children: [
          {
            path: "new",
            name: "CreateCategory",
            component: CreateCategory,
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalizedLoaded,
    next: NavigationGuardNext
  ) => {
    if (to.matched.length === 0) {
      // Unknown route
      const auth = getAuth();
      try {
        await new Promise<void>((resolve, reject) => {
          const unsubscribe = onAuthStateChanged(auth, (user) => {
            unsubscribe();
            if (user) {
              // User is logged in
              interface Company {
                _id: number;
                name: string;
                company_username: string;
                // ... other properties
              }
              const companyObj = localStorage.getItem("currentCompany");
              if (companyObj !== null) {
                const company: Company = JSON.parse(companyObj);
                if (company && company?.company_username) {
                  next({
                    name: "CompanyDashboard",
                    params: { companyId: company?._id },
                  });
                } else {
                  next({
                    name: "UserDashboard",
                  });
                }
              } else {
                next({
                  name: "UserDashboard",
                });
              }
            } else {
              // User is not logged in
              next({ name: "LoginView" });
            }
            resolve();
          });
        });
      } catch (error) {
        console.error("Error checking authentication:", error);
        next({ path: "/error" });
      }
    } else if (to.matched.some((record) => record.meta.authRequired)) {
      // Authenticated route
      const auth = getAuth();
      try {
        await new Promise<void>((resolve, reject) => {
          const unsubscribe = onAuthStateChanged(auth, (user) => {
            unsubscribe();
            if (user) {
              // User is authenticated
              next();
            } else {
              // User is not authenticated
              next({ name: "LoginView" });
            }
            resolve();
          });
        });
      } catch (error) {
        console.error("Error checking authentication:", error);
        next({ path: "/error" });
      }
    } else {
      // Non-authenticated route
      next();
    }
  }
);

export default router;
