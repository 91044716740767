<template>
  <div class="itinerary">
    <div class="itinerary-inner">
      <div class="itinerary__days" v-for="(day, i) in days" :key="i">
        <div class="days__date-wrap">
          <div class="days__date">
            <img
              src="@/assets/icons/angle-small-down.svg"
              alt=""
              class="days__date-icon"
            />

            <h5 class="days__date-text">Day{{ i + 1 }}</h5>
          </div>
        </div>

        <div
          class="days__item"
          :class="{
            '--no-border': i == days.length - 1 && j == day.schedule.length - 1,
          }"
          v-for="(item, j) in day.schedule"
          :key="j"
        >
          <div class="days__item-time">
            <h6 class="days__item-time-value">
              {{ convertTime(item.time).value }}
            </h6>
            <h6 class="days__item-time-unit">
              {{ convertTime(item.time).period }}
            </h6>
          </div>

          <div class="days__item-card">
            <h5 class="days__item-card-text">
              <img
                v-if="item.type == 'location'"
                src="@/assets/icons/location.svg"
                alt=""
                class="days__item-card-text-type-icon"
              />
              <img
                v-if="item.type == 'food'"
                src="@/assets/icons/food.svg"
                alt=""
                class="days__item-card-text-type-icon"
              />
              <img
                v-if="item.type == 'trek'"
                src="@/assets/icons/mountains.svg"
                alt=""
                class="days__item-card-text-type-icon"
              />
              {{ item.content.text }}
              <strong
                v-if="item.content.highlight"
                class="days__item-card-text-highlight"
              >
                {{ item.content.highlight }}</strong
              >
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["itinerary"],
  data() {
    return {
      time: null,
      days: [],
    };
  },
  mounted() {
    this.days = this.itinerary;
  },
  methods: {
    convertDate(d) {
      const date = new Date(d); // Replace this with your desired date

      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };

      const formattedDate = date.toLocaleDateString("en-US", options);
      return formattedDate;
    },
    convertTime(time) {
      const [hours, minutes] = time.split(":");

      let hoursNum = parseInt(hours, 10);

      const period = hoursNum >= 12 ? "PM" : "AM";

      hoursNum = hoursNum % 12 || 12;

      if (hoursNum < 10) {
        hoursNum = `0${hoursNum}`;
      }

      const formattedTime = `${hoursNum}:${minutes}`;

      return {
        value: formattedTime,
        period: period,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.itinerary {
  // margin: $sp-xl 0;
  padding-left: $sp-xs;
  .itinerary-inner {
    .itinerary__days {
      margin-bottom: $sp-md;
      .days__date-wrap {
        margin: $sp-sm 0;
        .days__date {
          width: calc(100% - 11px); //11px half width of arrow icon
          margin-left: 11px;
          display: flex;
          align-items: center;
          position: relative;
          .days__date-icon {
            position: absolute;
            left: -11px;
            width: calc($icon-w-md - 1px);
            opacity: 0.5;
          }
          .days__date-text {
            margin-left: calc(11px + $sp-sm);
            font-size: $txt-xs;
            color: $secondary-text-color;
          }
        }
      }

      .days__item {
        width: calc(100% - 11px); //11px half width of arrow icon
        margin-left: 11px;
        position: relative;
        border-left: 1px dashed $tertiary-text-color;
        padding-bottom: $sp-lg;
        margin-top: $sp-xs;

        &.--no-border {
          border: none;
        }

        .days__item-time {
          position: absolute;
          left: -11px;
          width: calc($icon-w-md - 1px);
          text-align: center;
          background-color: $secondary-bg-color;
          padding: $sp-xxs 0 $sp-xxs;

          .days__item-time-value,
          .days__item-time-unit {
            display: flex;
            justify-content: center;
            font-size: $txt-xs;
            color: $secondary-text-color;
          }
        }

        .days__item-card {
          width: fit-content;
          margin-left: calc(15px + $sp-sm);
          background-color: $primary-bg-color;
          border-radius: $br-sm;
          padding: $sp-sm $sp-md;

          .days__item-card-text {
            font-size: $txt-sm;
            font-weight: 100;
            line-height: $dis-16;

            .days__item-card-text-type-icon {
              width: $icon-w-tiny;
              margin-right: $sp-xxs;
            }
            .days__item-card-text-highlight {
              margin-left: $sp-xs;
              // .days__item-card-text-location-icon {
              //   width: $icon-w-tiny;
              // }
            }
          }
        }
      }
    }
  }
}

// for desktop
@media (min-width: 600px) {
  .itinerary {
    margin: $sp-xxl 0;
    padding-left: $sp-md;
    .itinerary-inner {
      .itinerary__days {
        margin-bottom: $sp-md;
        .days__date-wrap {
          margin: $sp-md 0;
          .days__date {
            .days__date-text {
              margin-left: calc(11px + $sp-md);
              font-size: $txt-sm;
            }
          }
        }

        .days__item {
          margin-top: $sp-xs;

          .days__item-time {
            .days__item-time-value,
            .days__item-time-unit {
              font-size: $txt-sm;
            }
          }

          .days__item-card {
            margin-left: calc(15px + $sp-md);
            padding: $sp-md $sp-lg;

            .days__item-card-text {
              font-size: $txt-md;
              line-height: $dis-16;

              .days__item-card-text-type-icon {
                width: $icon-w-xxs;
                margin-right: $sp-xxs;
              }
              .days__item-card-text-highlight {
                margin-left: $sp-xs;
                // .days__item-card-text-location-icon {
                //   width: $icon-w-tiny;
                // }
              }
            }
          }
        }
      }
    }
  }
}
</style>
