<template>
  <section>
    <nav-bar :noSidbar="true"></nav-bar>
    <div class="create-view">
      <div class="create-view__wrap">
        <StepbarTrek v-if="routeName !== 'CreateOptions'"></StepbarTrek>
        <router-view></router-view>
      </div>
    </div>
  </section>
</template>

<script>
import StepbarTrek from "@/components/common/stepbars/StepbarTrek.vue";
import NavBar from "@/components/common/NavBarUser.vue";
export default {
  components: { NavBar, StepbarTrek },
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.create-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;

  .create-view__wrap {
    width: 100%;
    padding: $sp-nav-mob $sp-md;
    box-sizing: border-box;
  }
}
@media only screen and (min-width: 600px) {
  .create-view {
    // justify-content: center;
    .create-view__wrap {
      width: 500px;
      padding: $sp-nav-des 0;
      box-sizing: border-box;
    }
  }
}
</style>
