<template>
  <div class="instr">
    <div class="instr-inner">
      <div class="instr__list">
        <h4 class="instr__list-title">Things to carry</h4>
        <div
          class="instr__list-item"
          v-for="thing in thingsToCarry"
          :key="thing"
        >
          <img
            src="@/assets/icons/rhombus.svg"
            alt=""
            class="instr__list-item-icon"
          />
          <h5 class="instr__list-item-text">{{ thing }}</h5>
        </div>
      </div>
      <br />
      <div class="instr__list">
        <h4 class="instr__list-title">Instructions</h4>
        <div class="instr__list-item" v-for="item in instructions" :key="item">
          <img
            src="@/assets/icons/info.svg"
            alt=""
            class="instr__list-item-icon"
          />
          <h5 class="instr__list-item-text">{{ item }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["instructions", "thingsToCarry"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.instr {
  .instr-inner {
    .instr__list {
      background-color: $primary-bg-color;
      padding: $sp-lg;
      border-radius: $br-sm;
      .instr__list-title {
        margin-bottom: $sp-md;
        color: $secondary-text-color;
      }
      .instr__list-item {
        display: flex;
        align-items: flex-start;
        gap: $sp-sm;
        margin-bottom: $sp-lg;
        .instr__list-item-icon {
          width: $icon-w-sm;
          opacity: 0.6;
        }
        .instr__list-item-text {
          margin-top: 1px;
          font-weight: 200;
          font-size: $txt-xs;
        }
      }
    }
  }
}
</style>
