<template>
  <div class="dashboard-right-wrap">
    <h3 class="section-heading">
      {{ trek?.trek_name || "Tour name..." }}
      <img
        v-if="
          ['accepted', 'booked', 'confirmed', 'checked-in'].includes(activeTab)
        "
        src="@/assets/icons/download.svg"
        alt=""
        class="section-heading-menu-icon"
        :class="{ '--disabled': !bookings?.length }"
        @click.stop="downloadSheet"
      />
    </h3>
    <div class="section-heading-date">
      {{ filters?.filterDate ? convertDate(filters?.filterDate) : "--/--/--" }}

      <div class="booking-filters-wrap">
        <img
          src="@/assets/icons/search.svg"
          alt=""
          class="booking-filter-icon"
          @click="showSearchbox = !showSearchbox"
        />
        <img
          src="@/assets/icons/filter.svg"
          alt=""
          class="booking-filter-icon"
          @click="openFilterDrawer = true"
        />
      </div>
    </div>
    <div class="section-heading-search input_wrap" v-if="showSearchbox">
      <input
        type="text"
        class="section-heading-search-input"
        placeholder="Search by name, id"
        v-model="searchText"
        @keydown.enter="searchBookings"
      />
      <img
        class="section-heading-search-cross"
        src="@/assets/icons/cross-small.svg"
        alt=""
        @click="showSearchbox = false"
      />
    </div>

    <!-- FILTERS DRAWER -->
    <DrawerLayout
      :toggleDrawer="openFilterDrawer"
      @closeDrawer="openFilterDrawer = false"
    >
      <filters-section
        v-if="trek"
        :prifilledFilters="filters"
        :tickets="trek?.tickets"
        @filtersSelected="filtersSelected"
        @closeDrawer="openFilterDrawer = false"
      ></filters-section>
    </DrawerLayout>
    <!-- Dashboard Nav -->
    <div class="dashboard-right__nav">
      <!-- <div
        class="nav-item"
        :class="{ '--active': activeTab == 'pending' }"
        @click="changeActiveTab('pending')"
      >
        Requests
      </div> -->
      <div
        class="nav-item"
        :class="{ '--active': activeTab == 'accepted' }"
        @click="changeActiveTab('accepted')"
      >
        Bookings
      </div>
      <div
        class="nav-item"
        :class="{ '--active': activeTab == 'booked' }"
        @click="changeActiveTab('booked')"
      >
        Confirmed
      </div>
      <div
        class="nav-item"
        :class="{ '--active': activeTab == 'checked-in' }"
        @click="changeActiveTab('checked-in')"
      >
        Checked-In
      </div>
      <!-- <div
        class="nav-item"
        :class="{ '--active': activeTab == 'other' }"
        @click="changeActiveTab('other')"
      >
        Other
      </div> -->
    </div>
    <!-- Dashboard Nav -->

    <!-- FILTER ROW -->
    <div
      class="filters-row"
      v-if="
        filters &&
        (filters.filterTicket || filters.filterPickup || filters.filterDate)
      "
    >
      <div class="filters-item" v-if="filters.filterDate">
        Date:
        <div class="filter-value">{{ convertDate(filters?.filterDate) }}</div>
        <img
          src="@/assets/icons/cross-small.svg"
          alt=""
          class="filter-remove-icon"
          @click="removeFilter('filterDate')"
        />
      </div>
      <div class="filters-item" v-if="filters.filterTicket">
        Ticket:
        <div class="filter-value">{{ filters?.filterTicket?.label }}</div>
        <img
          src="@/assets/icons/cross-small.svg"
          alt=""
          class="filter-remove-icon"
          @click="removeFilter('filterTicket')"
        />
      </div>
      <div class="filters-item" v-if="filters.filterPickup">
        Pickup:
        <div class="filter-value">{{ filters?.filterPickup?.label }}</div>
        <img
          src="@/assets/icons/cross-small.svg"
          alt=""
          class="filter-remove-icon"
          @click="removeFilter('filterPickup')"
        />
      </div>
    </div>
    <!-- FILTER ROW -->

    <!-- BOOKINGS LIST SECTION -->
    <div class="dashboard-bookings-wrap">
      <div
        class="no-bookings"
        v-if="!loadingBookings && (!bookings || (bookings && !bookings.length))"
      >
        <h4>No Bookings in {{ activeTab }} yet!</h4>
      </div>
      <div
        v-show="loadingBookings"
        class="shimmers"
        v-for="s in loadingBookings * 6"
        :key="s"
      ></div>

      <!-- BOOKINGS LIST -->
      <div class="bookings-list">
        <div
          class="booking-list-item"
          v-for="(booking, bi) in bookings"
          :key="booking._id"
        >
          <div class="booking-list-item-short">
            <div
              class="booking-item-details"
              :class="{
                '--full-width': ['booked', 'confirmed', 'checked-in'].includes(
                  booking.booking_status
                ),
              }"
            >
              <div class="customer-line">
                <div class="booking-customer-name">
                  {{ booking.customer.customer_name }}
                </div>
                <div class="booking-seats">
                  <img
                    src="@/assets/icons/user.svg"
                    alt=""
                    class="booking-seats-icon"
                  />
                  {{ booking.seats.length }}
                </div>
              </div>
              <div class="hr m-0 --dashed"></div>
              <div class="pickup-line">
                <div class="booking-id">
                  {{ convertDate(booking.event_date) }}
                </div>
                <div class="booking-id">
                  {{ booking.booking_id }}
                </div>

                <div
                  class="booking-pickup-address"
                  v-if="booking.ticket?.ticket_heading"
                >
                  <img
                    src="@/assets/icons/ticket.svg"
                    alt=""
                    class="booking-location-icon"
                  />
                  {{ `${booking?.ticket?.ticket_heading}` }}
                </div>
              </div>
            </div>
            <div
              class="booking-item-actions"
              :class="{ '--disabled': loadUpdate.includes(booking._id) }"
              v-if="
                ['accepted', 'booked', 'confirmed'].includes(
                  booking.booking_status
                )
              "
            >
              <!-- <div
                class="booking-status-tag"
                v-if="booking.booking_status !== 'pending'"
              >
                {{ booking.booking_status }}
              </div> -->
              <div
                class="booking-item-actions-accept"
                @click="changeStatus(booking._id, 'booked', bi)"
                v-if="booking.booking_status == 'accepted'"
              >
                Mark Paid
              </div>
              <div
                class="booking-item-actions-accept"
                @click="changeStatus(booking._id, 'checked-in', bi)"
                v-if="['booked', 'confirmed'].includes(booking.booking_status)"
              >
                Check In
              </div>
              <div
                class="booking-item-actions-accept"
                :class="{ '--disabled': booking.booking_status == 'accepted' }"
                @click="changeStatus(booking._id, 'accepted', bi)"
                v-if="booking.booking_status == 'pending'"
              >
                {{
                  booking.booking_status !== "accepted" ? "Accept" : "Accepted"
                }}
              </div>
              <div
                class="booking-item-actions-details"
                @click="changeStatus(booking._id, 'rejected', bi)"
                v-if="booking.booking_status == 'pending'"
              >
                Reject
              </div>
              <div
                class="booking-item-actions-details"
                @click="changeStatus(booking._id, 'pending', bi)"
                v-if="booking.booking_status == 'rejected'"
              >
                Un-Reject
              </div>
            </div>
          </div>
          <div
            class="booking-list-item-info"
            v-if="
              ['accepted', 'booked', 'checked-in'].includes(
                booking.booking_status
              )
            "
          >
            <div
              class="booking-customer-info"
              v-if="openedBookingDetails.includes(booking._id)"
            >
              <p class="booking-customer-info-item">
                <span>Contact No:</span> {{ booking.customer.contact_no }}
              </p>
              <p class="booking-customer-info-item">
                <span>Email:</span> {{ booking.customer.contact_email }}
              </p>
              <div class="booking-customer-info-item">
                <span>Pickup:</span>
                <p>
                  {{ `${booking.pickup_point.full_address}` }}
                </p>
              </div>
              <p class="booking-customer-info-item">
                <span>Payment:</span> {{ booking.payment_status }}
              </p>

              <div class="booking-list-item-info-actions">
                <a :href="`tel:${booking.customer.contact_no}`">
                  <img
                    src="@/assets/icons/call.svg"
                    alt=""
                    class="booking-list-item-info-actions-icon --call"
                  />
                </a>
                <a :href="`https://wa.me/91${booking.customer.contact_no}`">
                  <img
                    src="@/assets/icons/whatsapp.svg"
                    alt=""
                    class="booking-list-item-info-actions-icon --wp"
                  />
                </a>
                <!-- <img
                  src="@/assets/icons/call.svg"
                  alt=""
                  class="booking-list-item-info-actions-icon --call"
                /> -->
                <div
                  class="pill_btn --small"
                  @click="openedBookingDetailsPage(booking._id)"
                >
                  View Details
                </div>
              </div>
            </div>
            <div
              class="booking-list-item-info-toggle"
              @click="toggleDetails(booking._id)"
            >
              <img
                src="@/assets/icons/angle-small-down.svg"
                alt=""
                class="booking-list-item-info-toggle-icon"
                :class="{
                  '--open': openedBookingDetails.includes(booking._id),
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Bookings List -->
    </div>

    <DrawerLayout
      :toggleDrawer="openCheckinDrawer"
      @closeDrawer="openCheckinDrawer = false"
    >
      <!-- <add-new-booking
        v-if="trek"
        @cancelAdd="openCheckinDrawer = false"
        @addedBooking="addedBooking"
      ></add-new-booking> -->
      <check-in-booking
        v-if="openCheckinDrawer"
        @checkedin="checkinBooking"
        @close="openCheckinDrawer = false"
      ></check-in-booking>
    </DrawerLayout>
  </div>
  <!-- <div class="content-add-btn-wrap">
    <div class="content-add-btn" @click="openAddBookingDrawer = true">
      <img src="@/assets/icons/add.svg" alt="" class="add-btn-circle-icon" />
      Add Booking
    </div>
  </div> -->
  <div class="content-add-btn-wrap">
    <div class="content-add-btn" @click="openCheckinDrawer = true">
      <img src="@/assets/icons/scan.svg" alt="" class="add-btn-circle-icon" />
      Check-In
    </div>
  </div>
</template>

<script>
import DrawerLayout from "@/components/common/directives/DrawerLayout.vue";
import LoaderSpinner from "@/components/common/LoaderSpinner.vue";
// import DropDown from "@/components/inputs/DropDown.vue";
// import AddNewBooking from "@/components/dashboard/trek/bookings/AddNewBooking.vue";
import CheckInBooking from "@/components/dashboard/trek/bookings/CheckInBooking.vue";
import BookingGetters from "@/services/bookings/GetterService";
import BookingSetters from "@/services/bookings/SetterService";
import computedMixin from "@/mixins/computedMixin";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import exportFromJSON from "export-from-json";
import FiltersSection from "./FiltersSection.vue";

export default {
  mixins: [computedMixin],
  components: {
    // LoaderSpinner,
    DrawerLayout,
    // AddNewBooking,
    CheckInBooking,
    FiltersSection,
  },
  // props: ["trek"],
  data() {
    return {
      loadingBookings: false,
      loadUpdate: [],
      bookings: null,
      activeTab: "accepted",
      openCheckinDrawer: false,
      openedBookingDetails: [],
      openFilterDrawer: false,
      filters: null,
      showSearchbox: false,
      searchText: null,
    };
  },
  watch: {
    showSearchbox(val) {
      if (!val) {
        this.searchText = null;
        this.searchBookings();
      }
    },
  },
  mounted() {
    this.loadingBookings = true;
    this.activeTab = this.$route.query.tab || this.activeTab;
    this.getBookings(JSON.stringify(this.filters));
    // this.getTrekBookingsByStatusServer(this.trek._id, this.activeTab);
  },
  computed: {
    trekId() {
      return this.$route.params.trekId;
    },
  },
  methods: {
    downloadSheet() {
      // let list = [];
      // this.bookings.forEach((b, i) => {
      //   list.push({
      //     SR: i + 1,
      //     ID: b?.booking_id,
      //     CUSTOMER_NAME: b?.customer?.customer_name,
      //     SEATS: b?.seats?.length,
      //     CONTACT: b?.customer?.contact_no?.toString(),
      //     PICKUP_POINT: b?.pickup_point?.full_address,
      //   });
      // });
      // const data = list;
      // const fileName = this.trek.trek_name;
      // const exportType = exportFromJSON.types.csv;

      // exportFromJSON({ data, fileName, exportType });

      // Attendance
      let list = [];
      this.bookings.forEach((b, i) => {
        b?.seats?.forEach((s) => {
          list.push({
            SR: i + 1,
            ID: b?.booking_id,
            NAME: s?.name,
            CONTACT: b?.customer?.contact_no?.toString(),
            PICKUP_POINT: b?.pickup_point?.full_address,
          });
        });
      });
      const data = list;
      const fileName = `Attendee-${this.trek.trek_name}-${this.trek.event_date}`;
      const exportType = exportFromJSON.types.csv;

      exportFromJSON({ data, fileName, exportType });
    },
    async shareOnSocialMedia() {
      try {
        if (navigator.share) {
          await navigator.share({
            title: this.trek.trek_name,
            text: this.trek.trek_name,
            url: `https://travvify.com/e/${this.trek.trek_slug}`,
          });
        } else {
          toast.error("Web Share API not supported on your browser", {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      } catch (error) {
        // toast.error("Something went wrong, please try again!", {
        //   autoClose: 3000,
        //   hideProgressBar: true,
        // });
      }
    },
    async changeStatus(id, status, index) {
      try {
        this.loadUpdate.push(id);
        await BookingSetters.changeStatus(id, status);
        this.bookings.find((b) => b._id == id).booking_status = status;
        this.loadUpdate.splice(this.loadUpdate.length - 1, 1);
      } catch (error) {
        toast.error("Something went wrong!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
      // this.bookings.splice(index, 1);
    },
    async checkinBooking(booking) {
      // console.log(booking, "checkin");
      if (this.bookings.find((b) => b._id == booking._id)) {
        // console.log("booking", "checkin status");
        this.bookings.find((b) => b._id == booking._id).booking_status =
          "checked-in";
      }
      if (
        this.activeTab == "checked-in" &&
        !this.bookings.find((b) => b._id == booking._id)
      ) {
        // console.log("booking checkin", "add");
        this.bookings.splice(0, 0, booking);
      }
    },
    convertDate(d) {
      const date = new Date(d); // Replace this with your desired date

      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };

      const formattedDate = date.toLocaleDateString("en-US", options);
      return formattedDate;
    },
    toggleDetails(id) {
      if (this.openedBookingDetails.includes(id)) {
        // If the ID exists, remove it from the array
        const index = this.openedBookingDetails.indexOf(id);
        this.openedBookingDetails.splice(index, 1);
      } else {
        // If the ID doesn't exist, add it to the array
        this.openedBookingDetails.push(id);
      }
    },
    addedBooking(booking) {
      this.openAddBookingDrawer = false;
      this.bookings.splice(0, 0, booking);
    },
    searchBookings() {
      this.loadingBookings = true;
      this.bookings = [];
      this.getBookings(JSON.stringify(this.filters), this.searchText);
    },
    filtersSelected(filters) {
      this.filters = filters;
      this.loadingBookings = true;
      this.bookings = [];
      this.openFilterDrawer = false;
      this.getBookings(JSON.stringify(this.filters), this.searchText);
    },
    removeFilter(filter) {
      this.filters[filter] = null;
      this.loadingBookings = true;
      this.bookings = [];
      this.openFilterDrawer = false;
      this.getBookings(JSON.stringify(this.filters), this.searchText);
    },
    async getBookings(filters, searchText) {
      try {
        const bookings = await BookingGetters.getBookingsByStatus(
          this.trekId,
          this.activeTab,
          filters,
          searchText
        );
        this.bookings = bookings;
        this.loadingBookings = false;
      } catch (error) {
        toast.error("Something went wrong!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    },
    changeActiveTab(tab) {
      this.bookings = null;
      this.activeTab = tab;
      this.loadingBookings = true;
      this.getBookings(JSON.stringify(this.filters), this.searchText);
      this.$router.push({
        query: { tab: tab },
      });
    },
    openedBookingDetailsPage(bid) {
      this.$router.push({
        query: {
          bid: bid,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.content-add-btn-wrap {
  position: fixed;
  bottom: $sp-md;
  right: $sp-md;
  display: flex;
  justify-content: flex-end;
  padding: $sp-md 0;

  .content-add-btn {
    width: fit-content;
    padding: $sp-sm $sp-md;
    padding-right: $sp-md;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $sp-sm;
    background-color: $theme-bg-color;
    box-shadow: 0 0 25px $shadow-color-xl;
    font-size: $txt-sm;
    cursor: pointer;

    &:hover {
      background-color: $theme-bg-color-hover;
    }

    .add-btn-circle-icon {
      width: $icon-w-md;
    }
  }
}

.section-heading {
  background-color: $secondary-bg-color-hover;
  padding: $sp-sm $sp-md $sp-xxs;
  border-radius: $br-xs $br-xs 0 0;
  position: sticky;
  top: 0;
  box-shadow: 0 -25px 25px 20px $shadow-color-xxl;
  z-index: 2;
  font-size: $txt-lg;
  color: $primary-text-color;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .section-heading-menu-icon {
    cursor: pointer;
    width: $icon-w-xs;
  }
}
.section-heading-date {
  background-color: $secondary-bg-color-hover;
  padding: $sp-sm $sp-md $sp-md;
  position: sticky;
  top: 0;
  font-size: $txt-sm;
  color: $primary-text-color;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .booking-filters-wrap {
    display: flex;
    align-items: center;
    gap: $sp-lg;
    .booking-filter-icon {
      width: $icon-w-sm;
      cursor: pointer;
    }
  }
}
.section-heading-search {
  position: relative;

  .section-heading-search-cross {
    width: $icon-w-sm;
    position: absolute;
    right: $sp-md;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.dashboard-right__nav {
  position: sticky;
  top: 3px;
  width: calc(100%);
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: $secondary-bg-color;
  border-radius: $br-xs;
  overflow: hidden;
  z-index: 11;
  box-shadow: 0 0 10px 10px $shadow-color-md;

  .nav-item {
    padding: $sp-sm 0;
    width: 33.33%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid transparent;
    font-size: $txt-xs;
    cursor: pointer;

    &.--active {
      background-color: $secondary-bg-color-hover;
      border-color: $theme-bg-color;
    }
  }
}

.filters-row {
  display: flex;
  flex-wrap: wrap;
  gap: $sp-md;
  padding: $sp-md 0 0;
  .filters-item {
    background-color: $secondary-bg-color;
    padding: $sp-xs $sp-md;
    border-radius: 24px;
    display: flex;
    align-items: center;
    gap: $sp-sm;
    font-size: $txt-xs;
    color: $secondary-text-color;
    .filter-value {
      color: $primary-text-color;
    }

    .filter-remove-icon {
      width: $icon-w-xs;
      cursor: pointer;
    }
  }
}

.add-booking-btn {
  margin-top: $sp-lg;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $sp-sm;
  padding: $sp-md;
  // background-color: $secondary-bg-color;
  border: 1px solid $secondary-bg-color;
  color: $secondary-text-color;
  border-radius: $br-sm;
  cursor: pointer;
  .add-booking-btn-icon {
    opacity: 0.5;
  }
}

.no-bookings {
  padding: $sp-lg $sp-md;
  font-size: $txt-md;
  color: $tertiary-text-color;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $sp-xxxl 0;
}

.shimmers {
  height: 70px; /* Set the desired height of your card */
  border-radius: $br-sm;
  overflow: hidden;
  background-color: $secondary-bg-color-hover;
  margin: $sp-md 0;

  /* Apply the shimmer effect */
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, transparent, #070a123b, transparent);
    animation: shimmer 2s infinite;
  }

  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}

.bookings-list {
  padding: $sp-lg 0 70px;
  display: flex;
  flex-direction: column;
  gap: $sp-md;

  .booking-list-item {
    background-color: $secondary-bg-color-hover;
    border-radius: $br-sm;
    .booking-list-item-short {
      background-color: $secondary-bg-color;
      padding: $sp-md;
      border-radius: $br-sm;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $sp-lg;
      box-shadow: 0 4px 15px $shadow-color-lg;

      .booking-item-details {
        display: flex;
        flex-direction: column;
        width: 80%;
        gap: $sp-md;

        &.--full-width {
          width: 100%;
        }

        .customer-line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: $sp-xl;
          max-width: 100%;

          .booking-customer-name {
            font-size: $txt-md;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 230px;
          }
        }
        .booking-id {
          font-size: $txt-xs;
          font-weight: 600;
          white-space: nowrap;
        }
        .booking-seats {
          display: flex;
          align-items: center;
          gap: $sp-xs;
          font-size: $txt-sm;

          .booking-seats-icon {
            width: $icon-w-xxs;
            height: $icon-w-xxs;
          }
        }
        .booking-location-icon {
          width: $icon-w-xxs;
          height: $icon-w-xxs;
        }
        .pickup-line {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          font-size: $txt-xs;
          gap: $sp-md $sp-xxxl;
        }
        .booking-pickup-address {
          display: flex;
          align-items: center;
          gap: $sp-xs;
          font-size: $txt-sm;
          color: $secondary-text-color;
        }
      }
      .booking-item-actions {
        display: flex;
        flex-direction: column;
        gap: $sp-sm;
        .booking-status-tag {
          text-transform: capitalize;
          //background-color: $secondary-bg-color-hover;
          padding: $sp-xs;
          border-radius: $br-xs;
          font-size: $txt-xs;
          text-align: right;
        }
        .booking-item-actions-accept {
          text-align: center;
          background-color: $theme-bg-color;
          padding: $sp-xs $sp-md;
          border-radius: $br-xs;
          font-size: $txt-xs;
          white-space: nowrap;
          cursor: pointer;

          &:hover {
            background-color: $theme-bg-color-hover;
          }
        }
        .booking-item-actions-details {
          background-color: $error-bg-color;
          padding: $sp-xs $sp-md;
          border-radius: $br-xs;
          font-size: $txt-xs;
          white-space: nowrap;
          cursor: pointer;

          &:hover {
            background-color: $error-bg-color-hover;
          }
        }
      }
    }
    .booking-list-item-info {
      padding: $sp-md $sp-md 0;
      .booking-customer-info {
        padding-bottom: $sp-md;
        display: flex;
        flex-direction: column;
        gap: $sp-xs;
        .booking-customer-info-item {
          display: flex;
          font-size: $txt-xs;
          color: $secondary-text-color;

          span {
            color: $primary-text-color;
            margin-right: $sp-sm;
            display: inline-block;
            width: 80px;
          }
        }
        .booking-list-item-info-actions {
          display: flex;
          align-items: center;
          gap: $sp-xl;
          padding: $sp-lg $sp-sm 0;

          a {
            display: flex;
            align-items: center;
          }
          .booking-list-item-info-actions-icon {
            width: $icon-w-sm;
            cursor: pointer;

            &.--wp {
              transform: scale(1.2);
            }
            &.--call {
              // transform: scale(1.1);
            }
          }
        }
      }
      .booking-list-item-info-toggle {
        text-align: center;
        .booking-list-item-info-toggle-icon {
          width: $icon-w-sm;

          &.--open {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
@media (min-width: 600px) {
  .content-add-btn-wrap {
    bottom: $sp-lg;
    right: $sp-lg;

    .content-add-btn {
      padding: $sp-md;
      padding-right: $sp-lg;

      .add-btn-circle-icon {
        width: $icon-w-sm;
      }
    }
  }
  .dashboard-right__nav {
    .nav-item {
      padding: $sp-sm 0;

      font-size: $txt-sm;
    }
  }
}
</style>
