<template>
  <div class="dashboard-right-wrap">
    <div class="section-breadcrumb" @click="$router.back()">
      <img src="@/assets/icons/angle-left.svg" alt="" class="back-icon" />
      <h4 class="breadcrumb-item">Inquiries:</h4>
    </div>
    <div
      v-show="loadingInquiry"
      class="shimmers"
      v-for="s in loadingInquiry * 4"
      :key="s"
    ></div>
    <div class="booking-card" v-if="inquiry">
      <h3 class="heading-lg">{{ inquiry.customer.customer_name }}</h3>
      <h5 class="content-sm">{{ inquiry.customer.contact_email }}</h5>
      <h5 class="content-sm">{{ inquiry.customer.contact_no }}</h5>
      <div class="contact-btns">
        <a
          :href="`https://wa.me/91${inquiry.customer.contact_no}`"
          class="pill_btn --small --hollow --left-icon"
        >
          <img
            src="@/assets/icons/whatsapp.svg"
            alt=""
            class="contact-icon"
          />Whatsapp
        </a>
        <a
          :href="`tel:${inquiry.customer.contact_no}`"
          class="pill_btn --small --hollow --left-icon"
        >
          <img src="@/assets/icons/call.svg" alt="" class="contact-icon" />Call
        </a>
      </div>
    </div>

    <div class="booking-card" v-if="inquiry">
      <h3 class="heading-lg">Inquiry Id: {{ inquiry.inquiry_id }}</h3>
      <h5 class="content-sm">Inquiry for: {{ inquiry.inquiry_for_type }}</h5>
      <h5 class="content-sm">No of People: {{ inquiry.seats_quantity }}</h5>
      <h5 class="content-sm">
        Arrival Date: {{ inquiry.inquiry_data?.arrival_date }}
      </h5>
      <h5 class="content-sm">
        Departure Date:
        {{ inquiry.inquiry_data?.departure_date || "Not selected" }}
      </h5>
    </div>
    <div class="booking-card" v-if="inquiry">
      <h3 class="heading-lg">Preferences List</h3>
      <h5
        class="content-sm"
        v-for="(preference, p) in inquiry?.inquiry_data?.hotel_preferences"
        :key="p"
      >
        {{ p + 1 }})
        {{ preference.name }}
      </h5>
    </div>
  </div>
</template>

<script>
import InquiryGetters from "@/services/inquiries/GettersServices";
import computedMixin from "@/mixins/computedMixin";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  mixins: [computedMixin],
  data() {
    return {
      loadingInquiry: false,
      inquiry: null,
      customer: null,
      bookings: null,
      loadUpdate: [],
      openedBookingDetails: [],
    };
  },
  computed: {
    inquiryId() {
      return this.$route.query.iid;
    },
  },
  mounted() {
    this.getInquiryDetails();
  },
  methods: {
    async getInquiryDetails() {
      this.loadingInquiry = true;
      try {
        const inquiry = await InquiryGetters.inquiryById(
          this.inquiryId,
          this.companyId
        );
        this.inquiry = inquiry.inquiry;
        this.customer = inquiry.customer;
        this.bookings = inquiry.bookings;
        // console.log(inquiry);
      } catch (error) {
        console.log(error);
      }
      this.loadingInquiry = false;
    },
    convertDate(d) {
      const date = new Date(d); // Replace this with your desired date

      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };

      const formattedDate = date.toLocaleDateString("en-US", options);
      return formattedDate;
    },
    toggleDetails(id) {
      if (this.openedBookingDetails.includes(id)) {
        // If the ID exists, remove it from the array
        const index = this.openedBookingDetails.indexOf(id);
        this.openedBookingDetails.splice(index, 1);
      } else {
        // If the ID doesn't exist, add it to the array
        this.openedBookingDetails.push(id);
      }
    },
    openedBookingDetailsPage(bid) {
      this.$router.push({
        query: {
          bid: bid,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.shimmers {
  height: 180px; /* Set the desired height of your card */
  border-radius: $br-sm;
  overflow: hidden;
  background-color: $secondary-bg-color-hover;
  margin: $sp-md 0;

  /* Apply the shimmer effect */
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, transparent, #070a123b, transparent);
    animation: shimmer 2s infinite;
  }

  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}

.section-breadcrumb {
  display: flex;
  align-items: center;
  gap: $sp-md;
  padding: $sp-sm 0;
  cursor: pointer;
  .back-icon {
    width: $icon-w-sm;
    cursor: pointer;
  }

  .breadcrumb-item {
    color: $secondary-text-color;

    &.--current {
      color: $primary-text-color;
    }
  }
}

.booking-card {
  background-color: $secondary-bg-color;
  border-radius: $br-sm;
  padding: $sp-md;
  margin: $sp-md 0;
}
.heading-lg {
  font-size: $txt-lg;
  font-weight: 600;
  margin-bottom: $sp-sm;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.heading-md {
  font-size: $txt-md;
  font-weight: 400;
  margin-bottom: $sp-xs;
}
.heading-sm {
  font-size: $txt-sm;
  font-weight: 600;
  margin-bottom: $sp-xs;
  text-transform: capitalize;
}
.content-sm {
  font-size: $txt-sm;
  font-weight: 400;
  margin-bottom: $sp-xxs;
}
.contact-btns {
  display: flex;
  align-items: center;
  gap: $sp-md;
}

.seats-table {
  display: flex;
  flex-direction: column;
  gap: $sp-md;

  .seat-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.payment-status {
  font-size: $txt-md;
  text-transform: capitalize;
}
.price-table {
  display: flex;
  flex-direction: column;
  gap: $sp-md;

  .price-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.bookings-list {
  padding: $sp-lg 0 70px;
  display: flex;
  flex-direction: column;
  gap: $sp-md;

  .booking-list-item {
    background-color: $secondary-bg-color-hover;
    border-radius: $br-sm;
    .booking-list-item-short {
      background-color: $secondary-bg-color;
      padding: $sp-md;
      border-radius: $br-sm;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $sp-lg;
      box-shadow: 0 4px 15px $shadow-color-lg;

      .booking-item-details {
        display: flex;
        flex-direction: column;
        width: 80%;
        gap: $sp-md;

        &.--full-width {
          width: 100%;
        }

        .customer-line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: $sp-xl;
          max-width: 100%;

          .booking-customer-name {
            font-size: $txt-md;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 230px;
          }
        }
        .booking-id {
          font-size: $txt-xs;
          font-weight: 600;
          white-space: nowrap;
        }
        .booking-seats {
          display: flex;
          align-items: center;
          gap: $sp-xs;
          font-size: $txt-sm;

          .booking-seats-icon {
            width: $icon-w-xxs;
            height: $icon-w-xxs;
          }
        }
        .booking-location-icon {
          width: $icon-w-xxs;
          height: $icon-w-xxs;
        }
        .pickup-line {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          font-size: $txt-xs;
          gap: $sp-md $sp-xxxl;
        }
        .booking-pickup-address {
          display: flex;
          align-items: center;
          gap: $sp-xs;
          font-size: $txt-sm;
          color: $secondary-text-color;
        }
      }
      .booking-item-actions {
        display: flex;
        flex-direction: column;
        gap: $sp-sm;
        .booking-status-tag {
          text-transform: capitalize;
          //background-color: $secondary-bg-color-hover;
          padding: $sp-xs;
          border-radius: $br-xs;
          font-size: $txt-xs;
          text-align: right;
        }
        .booking-item-actions-accept {
          text-align: center;
          background-color: $theme-bg-color;
          padding: $sp-xs $sp-md;
          border-radius: $br-xs;
          font-size: $txt-xs;
          white-space: nowrap;
          cursor: pointer;

          &:hover {
            background-color: $theme-bg-color-hover;
          }
        }
        .booking-item-actions-details {
          background-color: $error-bg-color;
          padding: $sp-xs $sp-md;
          border-radius: $br-xs;
          font-size: $txt-xs;
          white-space: nowrap;
          cursor: pointer;

          &:hover {
            background-color: $error-bg-color-hover;
          }
        }
      }
    }
    .booking-list-item-info {
      padding: $sp-md $sp-md 0;
      .booking-customer-info {
        padding-bottom: $sp-md;
        display: flex;
        flex-direction: column;
        gap: $sp-xs;
        .booking-customer-info-item {
          display: flex;
          font-size: $txt-xs;
          color: $secondary-text-color;

          span {
            color: $primary-text-color;
            margin-right: $sp-sm;
            display: inline-block;
            width: 80px;
          }
        }
        .booking-list-item-info-actions {
          display: flex;
          align-items: center;
          gap: $sp-xl;
          padding: $sp-lg $sp-sm 0;

          a {
            display: flex;
            align-items: center;
          }
          .booking-list-item-info-actions-icon {
            width: $icon-w-sm;
            cursor: pointer;

            &.--wp {
              transform: scale(1.2);
            }
            &.--call {
              // transform: scale(1.1);
            }
          }
        }
      }
      .booking-list-item-info-toggle {
        text-align: center;
        .booking-list-item-info-toggle-icon {
          width: $icon-w-sm;

          &.--open {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
</style>
