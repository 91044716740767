import { Module } from "vuex";

interface Company {
  _id: number;
  company_name: string;
  company_username: string;
  email: string;
}

interface CompanyState {
  company: Company | null;
}

const companyModule: Module<CompanyState, any> = {
  namespaced: true,
  state: {
    company: null,
  },
  mutations: {
    setCompany(state, company) {
      state.company = company;
    },
  },
  actions: {
    setCompany({ commit }, company) {
      commit("setCompany", company);
    },
  },
  getters: {
    getCompany(state) {
      return state.company;
    },
  },
};

export default companyModule;
