<template>
  <create-card>
    <template v-slot:header>
      <!-- content for the header slot -->
      <div
        class="cancel-btn"
        @click="$router.push({ name: 'CompanyDashboard' })"
      >
        <img
          src="@/assets/icons/arrow-left.svg"
          alt=""
          class="cancel-btn-icon"
        />cancel
      </div>
      <h2 class="card-header">Create Event</h2>
      <p class="card-header-company-name">{{ trek?.trek_name }}</p>
    </template>

    <!-- <p class="section-note">This profile will be visible to your customers</p> -->
    <div
      class="pill_btn --hollow w-100"
      :class="{ '--disabled': loadingCopy }"
      @click="initCreateTrek"
    >
      Create New Event
    </div>

    <div class="option-or-line">
      <div class="or-line"></div>
      <h4>OR</h4>
      <div class="or-line"></div>
    </div>

    <h3>Copy Existing Event</h3>

    <!-- <input type="text" class="search-bar" placeholder="Search Events by Name" /> -->

    <copy-template-list
      :company="company"
      :tab="'all'"
      @copingEvent="loadingCopy = true"
    ></copy-template-list>
  </create-card>
</template>

<script>
import CreateCard from "@/components/layouts/CreateCard.vue";
import GetterService from "@/services/trek/GetterService";
import TrekSetters from "@/services/trek/SetterServices";
import computedMixin from "@/mixins/computedMixin";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import CopyTemplateList from "@/components/create/trek/CopyTemplateList.vue";

export default {
  mixins: [computedMixin],
  components: { CreateCard, CopyTemplateList },
  data() {
    return {
      loadingCopy: false,
    };
  },
  computed: {
    companyId() {
      return this.$route.params.companyId;
    },
    trekId() {
      return this.$route.params.trekId;
    },
    templateId() {
      return this.$route.query.tid;
    },
    currentRouteMeta() {
      return this.$route.meta;
    },
  },
  mounted() {
    if (this.trekId || this.templateId) {
      this.prefill();
    }
  },
  methods: {
    back() {
      this.$router.push({
        name: this.currentRouteMeta.preStep,
        params: { companyId: this.companyId },
        query: { trekId: this.trekId },
      });
    },
    initCreateTrek() {
      this.$router.push({
        name: "CreateTrekInfo",
        params: { companyId: this.company?._id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.cancel-btn {
  margin-top: $sp-lg;
  display: flex;
  align-items: center;
  gap: $sp-sm;
  width: fit-content;
  padding-bottom: $sp-lg;
  cursor: pointer;

  .cancel-btn-icon {
    width: $icon-w-xs;
  }
}

.--name-input {
  font-size: $txt-sm;
  padding: $sp-md;
}
.card-header-company-name {
  margin-bottom: 36px;
}
.card-header,
.card-header-company-name {
  text-align: left;
}
.--logo-input-wrap {
  display: flex;
  align-items: center;
  gap: $sp-md;
}

.option-or-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: $sp-xl 0;
  .or-line {
    width: 42%;
    border-bottom: 1px dashed $tertiary-text-color;
  }
}

.search-bar {
  margin: $sp-lg 0;
}
@media only screen and (min-width: 600px) {
  .--name-input {
    font-size: $txt-md;
    padding: $sp-md;
  }
}
</style>
