import axios from "axios";
import constants from "@/constants";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const url = `${constants.apiUrl}`;

class TrekGetters {
  static async templateById(templateId) {
    return await axios.get(
      `${url}/trek-template-getters/template-by-template-id/${templateId}`,
      (res, err) => {
        if (res) {
          return res;
        } else {
          return err;
        }
      }
    );
  }
  static async publicTrekById(trekId) {
    return await axios.get(
      `${url}/trek-template-getters/trek/${trekId}`,
      (res, err) => {
        if (res) {
          return res;
        } else {
          return err;
        }
      }
    );
  }
  static async trekById(trekId) {
    return await axios.get(
      `${url}/trek-template-getters/trek/${trekId}`,
      (res, err) => {
        if (res) {
          return res;
        } else {
          return err;
        }
      }
    );
  }
  static async getRecentTrekTemplates(companyId) {
    return await axios.get(
      `${url}/trek-template-getters/recent-templates/${companyId}`,
      (res, err) => {
        if (res) {
          return res;
        } else {
          return err;
        }
      }
    );
  }
  static async companyTreks(companyId, tab) {
    try {
      return new Promise((resolve, reject) => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            auth.currentUser
              .getIdToken(/* forceRefresh */ true)
              .then((idToken) => {
                const url = `${constants.apiUrl}/trek-template-getters/company-treks/${companyId}?status=${tab}`;
                const config = {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    Authorization: `Bearer ${idToken}`,
                  },
                };

                axios
                  .get(url, config)
                  .then((response) => {
                    const data = response.data;
                    unsubscribe();
                    resolve(data);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            reject("user not found");
          }
        });
      });
    } catch (error) {
      return error;
    }
  }
}

export default TrekGetters;
