<template>
  <create-card>
    <template v-slot:header>
      <!-- content for the header slot -->
      <h2 class="card-header">
        {{ trekId ? "Event Template Settings" : "New Event Template" }}
      </h2>
      <p class="card-header-company-name">
        {{ trekId ? trek?.trek_name : company?.company_name }}
      </p>
    </template>

    <!-- <p class="section-note">This profile will be visible to your customers</p> -->

    <div class="input_wrap my-15">
      <label for="smInput" class="sm-label">Event name</label>
      <input
        v-model="trekName"
        class="sm_input my-10"
        type="text"
        placeholder="Enter trek's name"
        autocomplete="off"
      />
    </div>
    <div class="input_wrap my-15">
      <h4 for="smInput" class="sm-label">Event Location</h4>

      <!-- <div class="trek-location-box">
        <select-location-form
          @locationAdded="addLocation"
          :prefillLocation="trekLocation"
          :noBtn="true"
          :noHeader="true"
        ></select-location-form>
      </div> -->
      <!-- <label for="smInput" class="sm-label">Base Camp name</label> -->

      <input
        v-model="trekLocation.basecamp"
        class="sm_input my-10"
        type="text"
        placeholder="Enter trek's / base camp location"
        autocomplete="off"
      />
    </div>

    <div class="input_wrap my-15">
      <div class="my-10 sm-label">Difficulty level (Optional)</div>
      <div
        class="dropdown_dumy"
        :class="{
          '--opened': showDifficultyDropdown,
          '--selected': selectedDifficulty.length,
        }"
        @click.stop="showDifficultyDropdown = !showDifficultyDropdown"
      >
        {{ selectedDifficulty[0] || "Select difficulty level of trek" }}
        <span class="close_icon" v-show="showDifficultyDropdown">x</span>
        <img
          v-show="!showDifficultyDropdown"
          src="@/assets/icons//angle-small-down.svg"
          alt=""
          class="close_icon"
        />
      </div>

      <DropDown
        v-if="showDifficultyDropdown"
        :options="difficultyOptions"
        :selectedOps="selectedDifficulty"
        @selected="difficultySelected"
        :key="difficultyOptions"
        :singleSelect="true"
      ></DropDown>
    </div>

    <div class="input_wrap my-15">
      <label for="smInput" class="sm-label my-10">Event Description</label>
      <textarea
        v-model="trekDescription"
        class="sm_input my-10"
        type="text"
        placeholder="Describe this trek here..."
        autocomplete="off"
        rows="15"
      ></textarea>
    </div>

    <div class="input_wrap my-15">
      <label for="smInput" class="sm-label my-10">Event Categories</label>
      <div
        class="dropdown_dumy mt-10"
        :class="{
          '--opened': showCategoryDropdown,
          '--selected': selectedCategories.length,
        }"
        @click.stop="showCategoryDropdown = !showCategoryDropdown"
      >
        {{
          selectedCategories.length
            ? selectedCategories.map((cat) => cat.label).join(", ")
            : "Select categories"
        }}
        <span class="close_icon" v-show="showCategoryDropdown">x</span>
        <img
          v-show="!showCategoryDropdown"
          src="@/assets/icons//angle-small-down.svg"
          alt=""
          class="close_icon"
        />
      </div>

      <DropDown
        v-if="showCategoryDropdown"
        :options="categoryOptions"
        :selectedOps="selectedCategories"
        @selected="categorySelected"
        :key="categoryOptions"
        :multiSelect="true"
        :searchBox="true"
      ></DropDown>
    </div>

    <div class="cta-wrap">
      <div class="pill_btn --hollow --left-icon" @click="back()">
        <img class="back-btn-icon" src="@/assets/icons/angle-left.svg" alt />
        Back
      </div>
      <div
        v-if="trekId"
        class="pill_btn --right-icon"
        @click="updateTrek()"
        :class="{
          '--disabled': loadingCreate || !trekName || !trekLocation,
        }"
      >
        Save & Next
        <img src="@/assets/icons/angle-right.svg" alt />
      </div>
      <div
        v-else
        class="pill_btn --right-icon"
        @click="createTrek()"
        :class="{
          '--disabled': loadingCreate || !trekName || !trekLocation,
        }"
      >
        Next
        <img src="@/assets/icons/angle-right.svg" alt />
      </div>
    </div>
  </create-card>
</template>

<script>
import CreateCard from "@/components/layouts/CreateCard.vue";
import GetterService from "@/services/trek/GetterService";
import TrekSetters from "@/services/trek/SetterServices";
import DropDown from "@/components/inputs/DropDown.vue";
import CategoryGetterService from "@/services/categories/GetterServices";
import computedMixin from "@/mixins/computedMixin";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  mixins: [computedMixin],
  components: {
    CreateCard,
    DropDown,
  },
  data() {
    return {
      loadingCreate: false,
      trekName: null,
      trekLocation: {},
      trekDescription: null,
      difficultyOptions: ["Easy", "Moderate", "Hard"],
      showDifficultyDropdown: false,
      selectedDifficulty: [],
      categoryOptions: [],
      showCategoryDropdown: false,
      selectedCategories: [],
    };
  },
  computed: {
    companyId() {
      return this.$route.params.companyId;
    },
    currentRouteMeta() {
      return this.$route.meta;
    },
    trekId() {
      return this.$route.query.trekId;
    },
    templateId() {
      return this.$route.query.tid;
    },
  },
  async mounted() {
    if (this.trekId || this.templateId) {
      this.prefill();
    }
  },
  methods: {
    back() {
      if (this.trekId) {
        this.$router.push({
          name: "TrekDashboard",
          params: { companyId: this.companyId, trekId: this.trekId },
        });
      } else {
        this.$router.push({
          name: "CompanyDashboard",
          params: { companyId: this.companyId },
        });
      }
    },
    async prefill() {
      let trek;
      if (this.trekId) {
        trek = await GetterService.publicTrekById(this.trekId);
      } else if (this.templateId) {
        trek = await GetterService.templateById(this.templateId);
      }
      this.trek = trek.data.trek;
      if (this.trek.trek_name) {
        this.trekName = this.trek.trek_name;
      }
      if (this.trek.trek_location) {
        this.trekLocation.basecamp = this.trek.trek_location.full_address;
        this.trekLocation.city = this.trek.trek_location.city;
        this.trekLocation.state = this.trek.trek_location.state;
        this.trekLocation.country = this.trek.trek_location.country;
      }
      if (this.trek.trek_description) {
        this.trekDescription = this.trek.trek_description;
      }
      if (this.trek.trek_difficulty_level) {
        this.selectedDifficulty = [this.trek.trek_difficulty_level];
      }
      await this.fetchCategories();
      if (this.trek.categories) {
        this.selectedCategories = this.trek.categories.map((cat) => ({
          id: cat,
          label: this.categoryOptions.find((c) => c.id === cat)?.label,
        }));
      }
    },
    async fetchCategories() {
      try {
        const response = await CategoryGetterService.getAllCategories(
          this.companyId
        );
        this.categoryOptions = response.map((category) => ({
          id: category._id,
          label: category.category_name,
        }));
      } catch (error) {
        toast.error("Failed to load categories", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    },
    difficultySelected(option) {
      this.selectedDifficulty = option;
      this.showDifficultyDropdown = false;
    },
    categorySelected(option) {
      this.selectedCategories = option;
      console.log(
        this.selectedCategories,
        this.selectedCategories.map((cat) => cat.id),
        "kdjdhkj"
      );
      this.showCategoryDropdown = false;
    },
    async updateTrek() {
      try {
        this.loadingCreate = true;
        const data = {
          trekId: this.trekId || null,
          companyId: this.company._id,
          trekName: this.trekName,
          trekLocation: this.trekLocation,
          trekDate: this.trekDate,
          trekDifficultyLevel: this.selectedDifficulty[0],
          trekDescription: this.trekDescription,
          trekCategories: this.selectedCategories.map((cat) => cat.id),
        };
        const trek = await TrekSetters.createTrek(data);
        this.$router.push({
          name: "CreateTrekPhotos",
          params: { companyId: this.companyId, trekId: trek._id },
        });
        this.loadingCreate = false;
      } catch (error) {
        toast.error("Something went wrong, please try again!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
        this.loadingCreate = false;
      }
    },
    async createTrek() {
      try {
        this.loadingCreate = true;
        const data = {
          companyId: this.company._id,
          trekName: this.trekName,
          trekLocation: this.trekLocation,
          trekDate: this.trekDate,
          trekDifficultyLevel: this.selectedDifficulty[0],
          trekDescription: this.trekDescription,
          trekCategories: this.selectedCategories.map((cat) => cat.id),
        };
        const trek = await TrekSetters.createTrek(data);
        this.$router.push({
          name: "CreateTrekPhotos",
          params: { companyId: this.companyId, trekId: trek._id },
        });
        this.loadingCreate = false;
      } catch (error) {
        toast.error("Something went wrong, please try again!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
        this.loadingCreate = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.card-header-company-name {
  margin-bottom: 36px;
}

.card-header,
.card-header-company-name {
  text-align: left;
}
.--logo-input-wrap {
  display: flex;
  align-items: center;
  gap: $sp-md;
}
.cta-wrap {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.trek-location-box {
  padding: 0 $sp-md;
  border-radius: $br-sm;
  border: 1px dashed $tertiary-text-color;
  margin: $sp-md 0 $sp-lg;
}
@media only screen and (min-width: 600px) {
}
</style>
