<template>
  <div class="category-list">
    <div class="category-list__wrap">
      <div
        class="category-card"
        v-for="category in categories"
        :key="category._id"
        @click="editCategory(category._id)"
      >
        <uppy-photo
          v-if="category.category_image"
          :imgName="category.category_image"
          alt=""
          class="category-image"
        />
        <div class="category-details">
          <div class="category-name">{{ category.category_name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UppyPhoto from "@/components/common/UppyPhoto.vue";
import CategoryGetterService from "@/services/categories/GetterServices";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import computedMixin from "@/mixins/computedMixin";

export default {
  mixins: [computedMixin],
  components: {
    UppyPhoto,
  },
  data() {
    return {
      categories: [],
    };
  },
  async mounted() {
    await this.fetchCategories();
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await CategoryGetterService.getAllCategories(
          this.companyId
        );
        this.categories = response;
      } catch (error) {
        toast.error("Failed to load categories", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    },
    editCategory(categoryId) {
      this.$router.push({ name: "CreateCategory", query: { categoryId } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.section-title {
  padding: $sp-md;
  font-size: $txt-xl;
  text-align: center;
  margin-top: 36px;
  color: $secondary-text-color;
}

.category-list__wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: $sp-md;
  justify-content: center;
  margin-top: $sp-lg;
}

.category-card {
  background-color: $secondary-bg-color;
  border: 1px solid $tertiary-text-color;
  border-radius: $br-sm;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .category-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-bottom: 1px solid $tertiary-text-color;
  }

  .category-details {
    padding: $sp-md;
    box-sizing: border-box;
    width: 100%;

    .category-name {
      font-size: $txt-lg;
      margin-bottom: $sp-xs;
      font-weight: bold;
      color: $primary-text-color;
    }

    .category-actions {
      display: flex;
      justify-content: space-around;
      margin-top: $sp-md;

      button {
        background-color: $theme-bg-color;
        color: $primary-text-color;
        border: none;
        padding: $sp-xs $sp-md;
        border-radius: $br-sm;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: $theme-bg-color-hover;
        }

        &.edit-btn {
          background-color: $theme-bg-color;

          &:hover {
            background-color: $theme-bg-color-hover;
          }
        }

        &.delete-btn {
          background-color: $error-text-color;

          &:hover {
            background-color: $error-text-color;
          }
        }
      }
    }
  }
}
</style>
