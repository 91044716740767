<template>
  <create-card>
    <template v-slot:header>
      <h2 class="card-header">
        {{ categoryId ? "Edit Event Category" : "New Event Category" }}
      </h2>
    </template>

    <div class="input_wrap my-15">
      <label for="categoryName" class="sm-label">Category Name</label>
      <input
        v-model="categoryName"
        class="sm_input my-10"
        type="text"
        placeholder="Enter category name"
        autocomplete="off"
      />
    </div>

    <div class="input_wrap my-15">
      <div class="file_input_dumy" @click="openUppyPhotos = true">
        <uppy-photo v-if="photo" :imgName="photo"></uppy-photo>
        <img
          v-if="!photo"
          class="file_input_icon"
          src="@/assets/icons/image.svg"
          alt=""
        />
        <span v-if="!photo">Upload category photo</span>
      </div>
      <UppyImgInput
        v-show="openUppyPhotos"
        @fileUploaded="fileUploadedPhotos"
        @close="openUppyPhotos = false"
        :filesLimit="1"
      />
    </div>

    <div class="cta-wrap">
      <div class="pill_btn --hollow --left-icon" @click="back()">
        <img class="back-btn-icon" src="@/assets/icons/angle-left.svg" alt />
        Back
      </div>
      <div
        v-if="categoryId"
        class="pill_btn --right-icon"
        @click="updateCategory()"
        :class="{ '--disabled': loadingCreate || !categoryName }"
      >
        Save & Next
        <img src="@/assets/icons/angle-right.svg" alt />
      </div>
      <div
        v-else
        class="pill_btn --right-icon"
        @click="createCategory()"
        :class="{ '--disabled': loadingCreate || !categoryName }"
      >
        Next
        <img src="@/assets/icons/angle-right.svg" alt />
      </div>
    </div>
    <div class="delete-btn" v-if="categoryId">
      <div class="pill_btn --danger --hollow" @click="deleteCategory()">
        Delete Category
      </div>
    </div>
  </create-card>
</template>

<script>
import CreateCard from "@/components/layouts/CreateCard.vue";
import UppyImgInput from "@/components/inputs/UppyInput.vue";
import CategorySetterService from "@/services/categories/SetterServices";
import CategoryGetterService from "@/services/categories/GetterServices";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import UppyPhoto from "@/components/common/UppyPhoto.vue";
import computedMixin from "@/mixins/computedMixin";

export default {
  mixins: [computedMixin],
  components: {
    CreateCard,
    UppyImgInput,
    UppyPhoto,
  },
  data() {
    return {
      loadingCreate: false,
      categoryName: null,
      photo: null,
      openUppyPhotos: false,
    };
  },
  computed: {
    categoryId() {
      return this.$route.query.categoryId;
    },
  },
  mounted() {
    if (this.categoryId) {
      this.prefill();
    }
  },
  methods: {
    back() {
      this.$router.push({
        name: "CompanyDashboard",
        params: { companyId: this.companyId },
        query: { section: "categories" },
      });
    },
    async prefill() {
      try {
        const category = await CategoryGetterService.getCategoryById(
          this.categoryId
        );
        console.log(category);
        this.categoryName = category?.category_name || "";
        this.photo = category?.category_image || "";
      } catch (error) {
        toast.error("Failed to load category data", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    },
    fileUploadedPhotos(e) {
      this.photo = e.name;
    },
    deletePhoto() {
      this.photo = null;
    },
    async updateCategory() {
      try {
        this.loadingCreate = true;
        const data = {
          category_name: this.categoryName,
          category_image: this.photo,
          company_id: this.companyId,
        };
        await CategorySetterService.updateCategory(this.categoryId, data);
        // this.$router.push({ name: "CategoryDashboard" });
        this.loadingCreate = false;
        toast.success("Category updated successfully", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      } catch (error) {
        toast.error("Something went wrong, please try again!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
        this.loadingCreate = false;
      }
    },
    async createCategory() {
      try {
        debugger;
        this.loadingCreate = true;
        const data = {
          category_name: this.categoryName,
          category_image: this.photo,
          company_id: this.companyId,
        };
        const createdCategory = await CategorySetterService.createCategory(
          data
        );
        this.$router.push({
          name: "CompanyDashboard",
          query: { section: "categories" },
          params: { companyId: this.companyId },
        });
      } catch (error) {
        console.error("Router push error:", error);
        toast.error("Something went wrong, please try again!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      } finally {
        this.loadingCreate = false;
      }
    },
    async deleteCategory() {
      try {
        if (confirm("Are you sure you want to delete this category?")) {
          await CategorySetterService.deleteCategory(this.categoryId);

          toast.success("Category deleted successfully", {
            autoClose: 3000,
            hideProgressBar: true,
          });

          this.$router.push({
            name: "CompanyDashboard",
            query: { section: "categories" },
            params: { companyId: this.companyId },
          });
        }
      } catch (error) {
        toast.error("Failed to delete category", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.card-header {
  text-align: left;
}
.cta-wrap {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.pill_btn {
  width: 100%;
  &.--danger {
    border-color: $error-text-color;
    color: $error-text-color;
  }
}
</style>
