<template>
  <div class="customers-view">
    <div class="customers-list" v-if="loadingCustomers">
      <div
        class="customers-list-item shimmers"
        v-for="s in loadingCustomers * 6"
        :key="s"
      ></div>
    </div>
    <div class="customers-list" v-if="customers?.length">
      <div
        class="customers-list-item"
        v-for="(customer, i) in customers"
        :key="i"
        @click="openCustomerDetails(customer)"
      >
        <h5>{{ customer?.name }}</h5>
        <img
          class="customers-list-item-angle-icon"
          src="@/assets/icons/angle-right.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import CustomerGetters from "@/services/customers/GetterServices";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  props: ["company", "tab"],
  data() {
    return {
      loadingCustomers: false,
      page: 1,
      customers: [],
    };
  },
  watch: {
    tab: {
      handler() {
        this.customers = [];
        this.loadingCustomers = true;
        this.getCustomers();
      },
      deep: true,
    },
  },
  mounted() {
    this.getCustomers();
  },
  methods: {
    async getCustomers() {
      try {
        this.loadingCustomers = true;
        const data = await CustomerGetters.companyCustomers(
          this.company._id,
          this.tab
        );
        this.customers = data;
        this.loadingCustomers = false;
        // console.log(this.customers);
      } catch (error) {
        this.loadingCustomers = false;
        console.log(error);
      }
    },
    openCustomerDetails(customer) {
      this.$router.push({ query: { section: "customers", cid: customer._id } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.shimmers {
  height: 24px; /* Set the desired height of your card */
  border-radius: $br-sm;
  overflow: hidden;
  background-color: $secondary-bg-color-hover;

  /* Apply the shimmer effect */
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, transparent, #070a123b, transparent);
    animation: shimmer 2s infinite;
  }

  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}

.customers-view {
  padding: $sp-lg 0;
  .customers-list {
    display: flex;
    flex-direction: column;
    gap: $sp-sm;

    .customers-list-item {
      background-color: $secondary-bg-color;
      padding: $sp-md $sp-md;
      border-radius: $br-sm;
      font-size: $txt-lg;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .customers-list-item-angle-icon {
        width: $icon-w-sm;
      }
    }
  }
}
</style>
