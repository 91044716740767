<template>
  <home-view>
    <template v-slot:sidebar>
      <!-- <h3 class="sidebar-title" v-if="trek">
        {{ trek?.trek_name }}
        <br />
        <span class="siderbar-title-date">{{
          convertDate(trek?.event_date)
        }}</span>
      </h3>
      <h3 class="sidebar-title" v-if="!trek">
        Event Name...
        <br />
        <span class="siderbar-title-date"> --/--/-- </span>
      </h3> -->
      <div class="sidebar-items">
        <div
          @click="changeSection('trek')"
          :class="{ '--active-section': activeSection == 'trek' }"
        >
          <div
            class="sidebar-item"
            :class="{ '--active-section': activeSection == 'trek' }"
          >
            <img
              class="sidebar-item-icon"
              src="@/assets/icons/mountains.svg"
              alt=""
            />
            View Event
          </div>
        </div>
        <a
          :href="`https://${company?.website_uri || 'travvify.com'}/e/${
            trek?.trek_slug
          }`"
          target="blank"
        >
          <div class="sidebar-item">
            <img
              class="sidebar-item-icon"
              src="@/assets/icons/link.svg"
              alt=""
            />
            Preview Event
          </div>
        </a>
        <div class="sidebar-item" @click="shareOnSocialMedia()">
          <img
            class="sidebar-item-icon"
            src="@/assets/icons/share.svg"
            alt=""
          />
          Share Event
        </div>
        <div
          class="sidebar-item"
          @click="changeSection('settings')"
          :class="{ '--active-section': activeSection == 'settings' }"
        >
          <img
            class="sidebar-item-icon"
            src="@/assets/icons/settings.svg"
            alt=""
          />
          Event Settings
        </div>
        <div
          class="sidebar-item"
          @click="changeSection('bookings')"
          :class="{ '--active-section': activeSection == 'bookings' }"
        >
          <img
            class="sidebar-item-icon"
            src="@/assets/icons/ticket.svg"
            alt=""
          />
          Event Bookings
        </div>
        <!-- <div
          class="sidebar-item"
          @click="copyTrek()"
          :class="{ '--active-section': activeSection == 'settings' }"
        >
          <img class="sidebar-item-icon" src="@/assets/icons/copy.svg" alt="" />
          Copy Trek
        </div> -->
        <div
          v-if="company"
          class="sidebar-item"
          @click="
            $router.push({
              name: 'CompanyDashboard',
              params: { companyUsername: company?.company_username },
            })
          "
          :class="{ '--active-section': activeSection == 'company' }"
        >
          <img
            class="sidebar-item-icon"
            src="@/assets/icons/calendar.svg"
            alt=""
          />
          Events List
        </div>
        <div
          v-if="company"
          class="sidebar-item"
          @click="
            $router.push({
              name: 'CompanyDashboard',
              params: { companyUsername: company?.company_username },
            })
          "
          :class="{ '--active-section': activeSection == 'company' }"
        >
          <img
            class="sidebar-item-icon"
            src="@/assets/icons/companies.svg"
            alt=""
          />
          Company Dashboard
        </div>
      </div>
      <div
        class="--logout"
        @click="
          changeSection('logout');
          logout();
        "
        :class="{ '--active-section': activeSection == 'logout' }"
      >
        <img
          class="sidebar-item-icon"
          src="@/assets/icons/sign-out.svg"
          alt=""
        />
        Log out
      </div>
    </template>

    <template v-slot:content>
      <div class="dashboard-left" v-if="activeSection == 'trek'">
        <div class="dashboard__org-wrapper">
          <!-- <profile-info :company="company"></profile-info> -->
          <trek-info v-if="trek" :trek="trek"></trek-info>
          <trek-details v-if="trek" :trek="trek"></trek-details>
          <div class="open-profile"></div>
        </div>
      </div>
      <div
        class="dashboard-right"
        v-if="activeSection == 'settings' && !bookingDetailId"
      >
        <event-settings
          v-if="trek"
          :event="trek"
          :company="company"
        ></event-settings>
      </div>
      <div
        class="dashboard-right"
        v-if="activeSection == 'bookings' && !bookingDetailId"
      >
        <booking-section></booking-section>
      </div>
      <div class="dashboard-right" v-if="bookingDetailId">
        <booking-details></booking-details>
      </div>
    </template>
  </home-view>
</template>

<script>
import { getAuth } from "firebase/auth";
import HomeView from "@/components/layouts/HomeView.vue";
import TrekInfo from "@/components/dashboard/trek/trek-details/TrekInfo.vue";
import TrekDetails from "@/components/dashboard/trek/trek-details/TrekDetails.vue";
import TrekGetters from "@/services/trek/GetterService";
import TrekSetters from "@/services/trek/SetterServices";
import BookingSection from "@/components/dashboard/trek/bookings/BookingSection.vue";
import BookingDetails from "@/components/dashboard/trek/bookings/BookingDetails.vue";
import EventSettings from "@/components/dashboard/trek/settings/EventSettings.vue";
import computedMixin from "@/mixins/computedMixin";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  mixins: [computedMixin],
  components: {
    TrekInfo,
    TrekDetails,
    BookingSection,
    BookingDetails,
    HomeView,
    EventSettings,
  },
  data() {
    return {
      activeSection: "bookings",
      activeTab: "enquiries",
    };
  },
  computed: {
    trekId() {
      return this.$route.params.trekId;
    },
    bookingDetailId() {
      return this.$route.query.bid;
    },
  },
  watch: {
    $route() {
      this.activeSection = this.$route.query.section || this.activeSection;
    },
  },
  mounted() {
    this.getTrek();
    this.activeSection = this.$route.query.section || this.activeSection;
  },
  methods: {
    convertDate(d) {
      const date = new Date(d); // Replace this with your desired date

      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };

      const formattedDate = date.toLocaleDateString("en-US", options);
      return formattedDate;
    },
    changeSection(sectionName) {
      this.activeSection = sectionName;
      this.$router.push({
        query: { section: sectionName },
      });
    },

    async getTrek() {
      //   this.getTrekServer(this.trekId);
      try {
        const trek = await TrekGetters.trekById(this.trekId);
        this.trek = trek.data.trek;
      } catch (error) {
        toast.error("Something went wrong, please try again!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    },
    async shareOnSocialMedia() {
      try {
        if (navigator.share) {
          await navigator.share({
            title: this.trek.trek_name,
            text: this.trek.trek_name,
            url: `https://${this.company.website_uri || "travvify.com"}/e/${
              this.trek.trek_slug
            }`,
          });
        } else {
          toast.error("Web Share API not supported on your browser", {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      } catch (error) {
        // toast.error("Something went wrong, please try again!", {
        //   autoClose: 3000,
        //   hideProgressBar: true,
        // });
      }
    },
    async copyTrek() {
      try {
        const copied = await TrekSetters.copyTrek(
          this.company._id,
          this.trek._id
        );
        this.$router.push({
          name: "CreateTrekInfo",
          params: { companyId: this.companyId },
          query: { trekId: copied._id },
        });
      } catch (error) {
        console.log(error);
      }
    },

    logout() {
      const auth = getAuth();
      auth.signOut().then(
        () => {
          this.$router.push("/auth/login");
        },
        function (error) {
          toast.error(error, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.sidebar-title {
  padding: $sp-md;
  box-sizing: border-box;
  border-bottom: 1px solid $primary-bg-color;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: $txt-sm;

  .siderbar-title-date {
    font-size: $txt-xs;
    color: $secondary-text-color;
  }
}
.sidebar-items {
  margin-top: $sp-sm;
  display: flex;
  flex-direction: column;
  // gap: $sp-sm;

  .sidebar-item {
    padding: $sp-lg $sp-md;
    box-sizing: border-box;
    font-size: $txt-sm;
    display: flex;
    align-items: center;
    gap: $sp-sm;
    color: $secondary-text-color;
    cursor: pointer;

    .sidebar-item-icon {
      width: $icon-w-xs;
      opacity: 0.6;
    }
    &:hover {
      background-color: $secondary-bg-color-hover;
    }

    &.--active-section {
      background-color: $primary-bg-color;
      color: $primary-text-color;

      .sidebar-item-icon {
        opacity: 1;
      }
    }
  }
}
.--logout {
  padding: $sp-lg $sp-lg;
  box-sizing: border-box;
  font-size: $txt-sm;
  display: flex;
  align-items: center;
  gap: $sp-sm;
  color: $primary-text-color;
  border-radius: $br-sm;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  width: 100%;

  .sidebar-item-icon {
    width: $icon-w-xs;
  }
  &:hover {
    background-color: $error-bg-color-hover;
  }

  &.--active-section {
    background-color: $error-bg-color;
    color: $primary-text-color;

    .sidebar-item-icon {
      opacity: 1;
    }
  }
}
// _____________CONTENT ___________________
.dashboard-left {
  width: calc(100%);
  overflow: hidden;
  position: relative;
  padding: 0 0 $sp-lg;
  box-sizing: border-box;
}
.dashboard-right {
  // padding-top: $sp-xxl;
  padding: 0 $sp-md $sp-lg;
  box-sizing: border-box;
  width: calc(100%);
  position: relative;
  // width: ;

  // .dashboard-right__nav {
  //   position: sticky;
  //   top: 0;
  //   width: calc(100%);
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-around;
  //   background-color: $secondary-bg-color;
  //   border-radius: $br-xs;
  //   overflow: hidden;
  //   z-index: 99;
  //   box-shadow: 0 0 10px 10px $shadow-color-xl;

  //   .nav-item {
  //     padding: $sp-sm 0;
  //     width: 50%;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     border-bottom: 2px solid transparent;
  //     font-size: $txt-xs;
  //     cursor: pointer;

  //     &.--active {
  //       background-color: $secondary-bg-color-hover;
  //       border-color: $theme-bg-color;
  //     }
  //   }
  // }
}
// _____________CONTENT ___________________

// for tablet
@media (min-width: 600px) {
  .sidebar-title {
    border-bottom: 1px solid $secondary-bg-color;
  }
  .sidebar-items {
    .sidebar-item {
      &.--active-section {
        background-color: $secondary-bg-color;
      }
    }
  }
  .dashboard-left {
    padding-bottom: $sp-lg;
    border-radius: $br-sm;

    .dashboard__org-wrapper {
      width: 100%;
    }
  }
  .dashboard-right {
    width: calc(100%);
    padding: 0 0 $sp-lg;

    .dashboard-right__nav {
      .nav-item {
        padding: $sp-sm 0;

        font-size: $txt-sm;
      }
    }
  }
}
// // for desktop
// @media (min-width: 1024px) {
//   .dashboard-left {
//     .dashboard__org-wrapper {
//       width: 100%;
//     }
//   }
// }
</style>
