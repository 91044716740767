<template>
  <section>
    <div class="itinerary">
      <div class="itinerary-inner">
        <div class="add-new-day-btn-wrap">
          <div class="add-new-day-btn --start" @click="addDay(-1)">
            <img src="@/assets/icons/add.svg" alt="" class="add-new-day-icon" />
            Day
          </div>
        </div>
        <div class="itinerary__days" v-for="(day, i) in days" :key="i">
          <div class="days__date-wrap">
            <div class="days__date">
              <!-- <img
                src="@/assets/icons/angle-small-down.svg"
                alt=""
                class="days__date-icon"
              /> -->

              <h3 class="days__date-text">Day {{ i + 1 }}</h3>
              <img
                src="@/assets/icons/delete.svg"
                alt=""
                class="delete-day-icon"
                @click="
                  removeDay(i);
                  $emit('dayRemoved', days.length);
                "
              />
            </div>
          </div>

          <div
            class="add-item"
            @click="addNewItemAt(i, j)"
            style="margin-left: 0px"
          >
            <img class="add-item-icon" src="@/assets/icons/add.svg" alt="" />
            <div class="add-item-text">Item</div>
          </div>

          <div
            class="days__item"
            :class="{
              '--no-border':
                i == days.length - 1 && j == day.schedule.length - 1,
            }"
            v-for="(item, j) in day.schedule"
            :key="j"
          >
            <div class="days__item-time">
              <h6 class="days__item-time-value">
                {{ convertTime(item.time).value }}
              </h6>
              <h6 class="days__item-time-unit">
                {{ convertTime(item.time).period }}
              </h6>
            </div>

            <div class="days__item-card" @click="editItem(i, j)">
              <h5 class="days__item-card-text">
                <img
                  v-if="item.type == 'location'"
                  src="@/assets/icons/location.svg"
                  alt=""
                  class="days__item-card-text-type-icon"
                />
                <img
                  v-if="item.type == 'food'"
                  src="@/assets/icons/food.svg"
                  alt=""
                  class="days__item-card-text-type-icon"
                />
                <img
                  v-if="item.type == 'trek'"
                  src="@/assets/icons/mountains.svg"
                  alt=""
                  class="days__item-card-text-type-icon"
                />
                {{ item.content.text }}
                <strong
                  v-if="item.content.highlight"
                  class="days__item-card-text-highlight"
                >
                  {{ item.content.highlight }}</strong
                >
              </h5>
              <img
                class="days__item-card-cross"
                src="@/assets/icons/edit.svg"
                alt=""
              />
              <img
                @click.stop="removeItem(i, j)"
                class="days__item-card-cross"
                src="@/assets/icons/cross-small.svg"
                alt=""
              />
            </div>
            <div class="add-item" @click="addNewItemAt(i, j)">
              <img class="add-item-icon" src="@/assets/icons/add.svg" alt="" />
              <div class="add-item-text">Item</div>
            </div>
          </div>
          <div class="add-new-day-btn-wrap">
            <div class="add-new-day-btn" @click="addDay(i)">
              <img
                src="@/assets/icons/add.svg"
                alt=""
                class="add-new-day-icon"
              />
              Day
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add modal -->
    <div class="add-modal" v-if="addAt" @click="closeAddNew()">
      <div class="add-modal-card" @click.stop>
        <div class="input_wrap my-15">
          <div class="my-10">Select template</div>
          <div
            class="dropdown_dumy --dark"
            :class="{
              '--opened': showItemTemplateDropdown,
              '--selected': selectedItemTemplate.length,
            }"
            @click.stop="showItemTemplateDropdown = !showItemTemplateDropdown"
          >
            {{ selectedItemTemplate[0] || "Select itinerary item template" }}
            <span class="close_icon" v-show="showItemTemplateDropdown">x</span>
            <img
              v-show="!showItemTemplateDropdown"
              src="@/assets/icons/angle-small-down.svg"
              alt=""
              class="close_icon"
            />
          </div>
          <DropDown
            class="--dark"
            v-if="showItemTemplateDropdown"
            :options="ItemTemplateOptions"
            :selectedOps="selectedItemTemplate"
            @selected="itemTemplateSelected"
            :key="ItemTemplateOptions"
            :singleSelect="true"
            :searchBox="true"
          ></DropDown>
        </div>
        <h5 class="text-center">OR</h5>
        <div class="input_wrap">
          <label for="smInput" class="sm-label">Edit item content</label>
          <textarea
            v-model="newItemContent"
            class="sm_input mt-10 --dark"
            type="text"
            placeholder="e.g: We Reach the Base village.."
            autocomplete="off"
            rows="5"
            @keypress="changeNewContent"
          ></textarea>
          <!-- <QuillEditor
            v-model="itemContent"
            contentType="html"
            theme="snow"
            toolbar="minimal"
            @textChange="changeTextValue"
          /> -->
        </div>
        <div class="input_wrap">
          <label for="smInput" class="sm-label"
            >Select time (24hrs clock)</label
          >
          <input
            v-model="newItemTime"
            class="sm_input my-10 w-fit-content --dark"
            type="time"
            placeholder="Enter date when company started"
            autocomplete="off"
          />
          <!-- <TimePicker></TimePicker> -->
        </div>
        <div class="ctas" v-if="addAt.edit">
          <div class="pill_btn --small" @click="addNewItem(true)">save</div>
          <div class="pill_btn --hollow --small" @click="addAt = null">
            Cancel
          </div>
        </div>
        <div class="ctas" v-else>
          <div
            class="pill_btn --small"
            @click="addNewItem()"
            :class="{ '--disabled': !newItemTime || !newItemContent }"
          >
            Add this item
          </div>
          <div class="pill_btn --hollow --small" @click="addAt = null">
            Cancel
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DropDown from "@/components/inputs/DropDown.vue";
// import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import TimePicker from "@/components/inputs/TimePicker.vue";
export default {
  components: { DropDown },
  props: ["itinerary"],
  data() {
    return {
      time: null,
      showItemTemplateDropdown: false,
      selectedItemTemplate: [],
      ItemTemplateOptions: [
        "ADD CUSTOM ITEM",
        "Start from pickup Point",
        "Reach base camp",
        "Have a break / rest",
        "Food / Refreshments",
        "Event begins",
        "Start Exploring",
        "Return to base camp",
        "Return journey starts",
        "Reach home destination",
      ],
      itemTemplates: [
        {
          option: "ADD CUSTOM ITEM",
          value: "",
        },
        {
          option: "Start from pickup Point",
          value: "Start your adventure from your pickup point",
        },
        {
          option: "Reach base camp",
          value: "We reach the base camp at base camp location",
        },
        {
          option: "Have a break / rest",
          value: "Have a break and rest",
        },
        {
          option: "Food / Refreshments",
          value: "Enjoy some delicious food and refreshing drinks",
        },
        {
          option: "Event begins",
          value:
            "Begin your trek, ready to explore the beautiful surroundings.",
        },
        {
          option: "Start Exploring Event",
          value:
            "Immerse yourself in the trek, taking in the sights and sounds.",
        },
        {
          option: "Return to base camp",
          value:
            "Return from the trek to base camp, bringing back memories and experiences.",
        },
        {
          option: "Return journey starts",
          value: "Start your journey back home, cherishing the moments.",
        },
        {
          option: "Reach home destination",
          value:
            "Finally, reach your home destination, feeling accomplished and fulfilled.",
        },
      ],
      newItemContent: null,
      newItemTime: null,
      days: [],
      noOfDays: 1,
      oldNoOfDays: 0,
      addAt: null,
    };
  },
  mounted() {
    if (this.itinerary && this.itinerary.length) {
      // console.log(this.itinerary, "itinerary");
      this.days = this.itinerary;
    } else {
      // this.days = [];
      this.getDaysArray();
    }
  },
  watch: {
    days: {
      handler(newValue, oldValue) {
        //both newValue and oldValue are the FULL object and not just what changed of it!
        this.$emit("itineraryChanged", newValue);
      },
      deep: true,
    },
    itinerary: {
      handler(newValue, oldValue) {
        //both newValue and oldValue are the FULL object and not just what changed of it!
        if (this.itinerary && this.itinerary.length) {
          this.days = this.itinerary;
          // console.log(this.days, "itinerary days");
        } else {
          this.days = this.getDaysArray();
        }
      },
      deep: true,
    },
  },
  methods: {
    getDaysArray() {
      console.log("hey");
      if (this.noOfDays > this.oldNoOfDays) {
        for (let d = 0; d < this.noOfDays - this.oldNoOfDays; d++) {
          this.days.push({
            day_no: d,
            schedule: [],
          });
        }
        this.oldNoOfDays = this.noOfDays;
      }
    },
    addDay(i) {
      const newDay = {
        day_no: i + 1,
        schedule: [],
      };
      this.days.splice(i + 1, 0, newDay);
    },
    removeDay(i) {
      if (this.days.length > 1) {
        if (confirm(`You will lose all items in DAY ${i + 1}`)) {
          this.days.splice(i, 1);
          this.$emit("dayRemoved", this.days.length);
          this.oldNoOfDays = this.days.length;
        }
      } else {
        alert("Atleast one day itinerary is required");
      }
    },
    closeAddNew() {
      this.addAt = null;
      (this.newItemContent = null), (this.newItemTime = null);
    },
    addNewItemAt(day, place) {
      this.addAt = {
        day: day,
        place: place + 1,
      };
    },
    addNewItem(edit) {
      let item = {
        time: this.newItemTime,
        type: "nan",
        category: "nan",
        content: {
          text: this.newItemContent,
          highlight: "",
        },
      };
      if (edit) {
        this.days[this.addAt.day].schedule.splice(this.addAt.place, 1); // while editing we are first removing existing item and below we are adding new at same place
      }
      this.days[this.addAt.day].schedule.splice(this.addAt.place, 0, item);
      this.addAt = null;
      this.newItemContent = "";
      this.selectedItemTemplate = [];
    },
    removeItem(i, j) {
      this.days[i].schedule.splice(j, 1);
    },
    editItem(i, j) {
      this.addAt = {
        day: i,
        place: j,
        edit: true,
      };
      this.newItemContent = this.days[i].schedule[j]?.content.text;
      this.newItemTime = this.days[i].schedule[j].time;
    },
    itemTemplateSelected(option) {
      this.selectedItemTemplate = option;
      this.showItemTemplateDropdown = false;
      let template = this.itemTemplates.find((item) => item.option == option);
      this.newItemContent = template.value;
    },
    convertTime(time) {
      const [hours, minutes] = time.split(":");

      let hoursNum = parseInt(hours, 10);

      const period = hoursNum >= 12 ? "PM" : "AM";

      hoursNum = hoursNum % 12 || 12;

      if (hoursNum < 10) {
        hoursNum = `0${hoursNum}`;
      }

      const formattedTime = `${hoursNum}:${minutes}`;

      return {
        value: formattedTime,
        period: period,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.itinerary {
  margin: $sp-lg 0;
  .itinerary-inner {
    .add-new-day-btn-wrap {
      height: 0;
      .add-new-day-btn {
        display: flex;
        align-items: center;
        gap: $sp-sm;
        border: 1px solid $tertiary-text-color;
        color: $tertiary-text-color;
        border-radius: $br-sm;
        font-size: $txt-xs;
        padding: $sp-xxs $sp-md;
        width: fit-content;
        cursor: pointer;
        //margin: auto;
        position: relative;
        top: 11px;
        background-color: $secondary-bg-color;

        &.--start {
          top: -10px;
        }

        &:hover {
          color: $primary-text-color;
          .add-new-day-icon {
            opacity: 1;
          }
        }
        .add-new-day-icon {
          width: $icon-w-xxs;
          opacity: 0.5;
        }
      }
    }
    .itinerary__days {
      margin: $sp-xl 0 $sp-md;
      border-bottom: 1px dashed $tertiary-text-color;
      padding: 0 0 $sp-xl;
      .days__date-wrap {
        margin: $sp-sm 0;
        .days__date {
          width: calc(100%); //11px half width of arrow icon
          //margin-left: 11px;
          display: flex;
          align-items: center;
          position: relative;
          gap: $sp-sm;
          padding-bottom: $sp-sm;
          border-bottom: 1px solid $primary-bg-color;
          .days__date-icon {
            position: absolute;
            left: -11px;
            width: calc($icon-w-md - 1px);
            opacity: 0.5;
          }
          .days__date-text {
            //  margin-left: calc(11px + $sp-sm + 11px);
            font-size: $txt-sm;
            color: $secondary-text-color;
          }
          .delete-day-icon {
            width: $icon-w-xs;
            margin-left: auto;
            cursor: pointer;
          }
        }
      }

      .days__item {
        width: calc(100% - 11px); //11px half width of arrow icon
        margin-left: 11px;
        position: relative;
        border-left: 1px dashed $tertiary-text-color;
        padding-bottom: $sp-sm;
        margin-top: $sp-xs;

        &.--no-border {
          border: none;
        }

        .days__item-time {
          position: absolute;
          left: -11px;
          width: calc($icon-w-md - 1px);
          text-align: center;
          background-color: $secondary-bg-color;
          padding: $sp-xxs 0 $sp-xxs;

          .days__item-time-value,
          .days__item-time-unit {
            display: flex;
            justify-content: center;
            font-size: $txt-xxs;
            color: $secondary-text-color;
          }
        }

        .days__item-card {
          width: fit-content;
          margin-left: calc(11px + $sp-sm);
          background-color: $primary-bg-color;
          border-radius: $br-sm;
          padding: $sp-sm $sp-sm $sp-sm $sp-md;
          display: flex;
          justify-content: space-between;
          gap: $sp-md;

          .days__item-card-text {
            font-size: $txt-xs;
            font-weight: 100;
            line-height: $dis-16;

            .days__item-card-text-type-icon {
              width: $icon-w-tiny;
              margin-right: $sp-xxs;
            }
            .days__item-card-text-highlight {
              margin-left: $sp-xs;
              // .days__item-card-text-location-icon {
              //   width: $icon-w-tiny;
              // }
            }
          }
          .days__item-card-cross {
            width: $icon-w-xs;
            cursor: pointer;
            opacity: 0.7;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.add-item {
  margin: $sp-sm 0 0 -11px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $sp-sm;
  cursor: pointer;
  color: $secondary-text-color;

  &.--empty {
    justify-content: start;
    padding: $sp-sm $sp-md;
    width: fit-content;
    margin-left: $sp-xxl;
    margin-bottom: $sp-xxl;
    border-radius: $br-sm;
    background-color: $primary-bg-color;
  }

  &:hover {
    color: $primary-text-color;
    .add-item-icon {
      opacity: 1;
    }
  }
  .add-item-icon {
    width: $icon-w-xxs;
    opacity: 0.7;
  }
  .add-item-text {
    font-size: $txt-xs;
  }
}
.add-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #070a123d;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $sp-md;
  box-sizing: border-box;
  z-index: 99;

  .add-modal-card {
    width: 100%;
    padding: $sp-md;
    border-radius: $br-sm;
    background-color: $secondary-bg-color;
    box-shadow: 0 0 $dis-14 $shadow-color-lg;
  }
}
.ctas {
  display: flex;
  align-items: center;
  gap: $sp-sm;
}

// for desktop
@media (min-width: 600px) {
  .itinerary {
    margin: $sp-lg 0;
    .itinerary-inner {
      .itinerary__days {
        margin-bottom: $sp-md;
        .days__date-wrap {
          margin: $sp-md 0;
          .days__date {
            .days__date-text {
              //margin-left: calc(11px + $sp-md);
              font-size: $txt-md;
            }
          }
        }

        .days__item {
          margin-top: $sp-xs;

          .days__item-time {
            .days__item-time-value,
            .days__item-time-unit {
              font-size: $txt-xs;
            }
          }

          .days__item-card {
            margin-left: calc(11px + $sp-md);
            padding: $sp-md $sp-md $sp-md $sp-lg;
            gap: $sp-sm;

            .days__item-card-text {
              font-size: $txt-sm;
              line-height: $dis-16;

              .days__item-card-text-type-icon {
                width: $icon-w-xxs;
                margin-right: $sp-xxs;
              }
              .days__item-card-text-highlight {
                margin-left: $sp-xs;
                // .days__item-card-text-location-icon {
                //   width: $icon-w-tiny;
                // }
              }
            }
          }
        }
      }
    }
  }

  .add-modal {
    .add-modal-card {
      width: 500px;
    }
  }
}
</style>
