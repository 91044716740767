import CompanyGetters from "../services/company/GetterServices";
import TrekGetters from "../services/trek/GetterService";

export default {
  computed: {
    user: {
      get: function () {
        return this.$store.state.user.user;
      },
      set: function (newValue) {
        this.$store.dispatch("user/setUser", newValue);
      },
    },
    companyUsername() {
      return this.$route.params.companyUsername;
    },
    companyId() {
      return this.$route.params.companyId;
    },
    company: {
      get: function () {
        return this.$store.state.company.company;
      },
      set: function (newValue) {
        this.$store.dispatch("company/setCompany", newValue);
      },
    },
    trekSlug() {
      return this.$route.params.trekSlug;
    },
    trekId() {
      return this.$route.params.trekId;
    },
    trek: {
      get: function () {
        return this.$store.state.trek.trek;
      },
      set: function (newValue) {
        if (newValue) {
          this.$store.dispatch("trek/setTrek", newValue);
        }
      },
    },
  },
  async mounted() {
    if (this.companyId) {
      await this.getCurrentCompany();
    }
    if (this.trekId) {
      await this.getCurrentTrek();
    }
  },
  methods: {
    async getCurrentCompany() {
      let locCompany = localStorage.getItem("currentCompany");
      if (locCompany && locCompany != undefined) {
        this.company = JSON.parse(locCompany);
      } else if (!this.company || this.company._id != this.companyId) {
        let company = await CompanyGetters.companyById(this.companyId);
        this.company = company.data;
        localStorage.setItem("currentCompany", JSON.stringify(this.company));
      }
    },
    async getCurrentTrek() {
      const locTrek = localStorage.getItem("currentTrek");
      if (
        locTrek &&
        locTrek != undefined &&
        (!this.trek || this.trek?._id != JSON.parse(locTrek)?._id)
      ) {
        this.trek = JSON.parse(locTrek);
      }
      if (!this.trek || this.trek._id != this.trekId) {
        let trek = await TrekGetters.publicTrekById(this.trekId);
        this.trek = trek.data.trek;
        this.company = trek.data.company;
        localStorage.setItem("currentCompany", JSON.stringify(this.company));
        localStorage.setItem("currentTrek", JSON.stringify(this.trek));
      }
    },
  },
};
