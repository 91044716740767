<template>
  <div class="auth_card">
    <h1 class="brand_name">
      <img class="brand_logo" src="@/assets/logos/short_logo.svg" alt="" />
      Travvify
    </h1>
    <form action="" method="post" @submit.prevent="login">
      <label class="input_label" for="Email">Email</label>
      <input
        id="Email"
        class="auth_input"
        type="email"
        placeholder="Enter your email"
        v-model="email"
      />
      <label class="input_label" for="Password">Password</label>
      <input
        id="Password"
        class="auth_input --password"
        type="password"
        placeholder="Enter a password"
        v-model="password"
        @keyup="validatePassword()"
      />
      <div class="input-note --error" v-if="invalidPassword">
        Password must be atleast 8 characters, should contail Uppercase,
        Lowercase & Digits
      </div>
      <input
        id="ConfirmPassword"
        class="auth_input --password"
        type="password"
        placeholder="Confirm password"
        v-model="confirmPassword"
      />
      <br />
      <button
        type="submit"
        class="auth_btn"
        :class="{ '--disabled': invalidPassword }"
        @click="register"
        v-if="!loading"
      >
        Sign-up
      </button>
    </form>
    <div class="auth_btn --google" @click="signupWithGoogle" v-if="!loading">
      <div class="google_icon_box">
        <img
          class="google_icon"
          src="@/assets/icons/google-icon.png"
          alt="google sign in logo"
        />
      </div>
      Sign-up with Google
    </div>
    <div class="auth_btn --loader" v-if="loading">
      <LoaderSpinner class="spinner"></LoaderSpinner>
    </div>
    <p>
      Already have an account?
      <router-link to="/login">Sign-in</router-link>
    </p>
  </div>
</template>

<script>
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import UserSetters from "@/services/user/SetterService";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import LoaderSpinner from "../common/LoaderSpinner.vue";
import computedMixin from "@/mixins/computedMixin";
import CompanyGetters from "@/services/company/GetterServices";

export default {
  mixins: [computedMixin],
  components: { LoaderSpinner },
  name: "RegisterView",
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      loading: false,
      invalidPassword: false,
    };
  },
  methods: {
    validatePassword() {
      this.invalidPassword = false;
      const minLength = 8;
      const hasUpperCase = /[A-Z]/.test(this.password);
      const hasLowerCase = /[a-z]/.test(this.password);
      const hasDigit = /\d/.test(this.password);

      // Check if the password meets the criteria
      if (this.password.length < minLength) {
        this.invalidPassword = true;
      }

      if (!hasUpperCase) {
        this.invalidPassword = true;
      }

      if (!hasLowerCase) {
        this.invalidPassword = true;
      }

      if (!hasDigit) {
        this.invalidPassword = true;
      }
    },
    async redirectLogin() {
      try {
        // console.log("hii");
        const company = JSON.parse(localStorage.getItem("currentCompany"));
        if (company && company?.company_username) {
          this.$router.push({
            name: "CompanyDashboard",
            params: { companyId: company?._id },
          });
        } else {
          const companies = await CompanyGetters.userCompanies();
          // console.log(companies, "companies");
          if (companies?.length) {
            this.$router.push({
              name: "CompanyDashboard",
              params: { companyId: companies[0]?._id },
            });
          } else {
            this.$router.push({ name: "CreateNewCompany" });
          }
        }
      } catch (error) {
        toast.error("Something went wrong", {
          autoClose: 3000,
          hideProgressBar: true,
        });
        this.$router.push({ name: "UserDashboard" });
      }
    },
    register() {
      this.loading = true;
      if (this.password == this.confirmPassword) {
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, this.email, this.password)
          .then(async (/*user*/) => {
            // Signed in
            try {
              let user = await UserSetters.authUser();
              if (user && user._id) {
                this.user = user;
                this.loading = false;
                this.$router.push({ name: "CreateNewCompany" });
              }
            } catch (error) {
              toast.error(
                "Sorry for inconvenience!, something went wrong, please try again!",
                {
                  autoClose: 3000,
                  hideProgressBar: true,
                }
              );
              this.loading = false;
            }
          })
          .catch((error) => {
            // put alert
            toast.error("Email already in use", {
              autoClose: 3000,
              hideProgressBar: true,
            });
            this.loading = false;
          });
      } else {
        this.loading = false;
        toast.warn("Please ensure your password and confirm password match.", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    },
    signupWithGoogle() {
      this.loading = true;
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(async (/*user*/) => {
          // Signed in
          try {
            let user = await UserSetters.authUser();
            if (user && user._id) {
              this.user = user;
              this.loading = false;
              this.redirectLogin();
            }
          } catch (error) {
            toast.error(
              "Sorry for inconvenience!, something went wrong, please try again!",
              {
                autoClose: 3000,
                hideProgressBar: true,
              }
            );
            this.loading = false;
          }
        })
        .catch((error) => {
          // put alert
          toast.error(
            "Sorry for inconvenience!, something went wrong, please try again!",
            {
              autoClose: 3000,
              hideProgressBar: true,
            }
          );
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.auth_card {
  width: 370px;
  color: $primary-text-color;
  margin-bottom: 7em;

  .brand_name {
    display: flex;
    align-items: center;
    margin-bottom: 2em;
    font-size: $txt-xl;
    letter-spacing: 7px;
    .brand_logo {
      width: 40px;
      margin-right: 15px;
    }
  }
}
.auth_input {
  box-sizing: border-box;
  width: 370px;
  padding: 1em;
  border-radius: $br-sm;
  display: block;
  background-color: $secondary-bg-color;
  color: $primary-text-color;
  outline: none;
  border: none;
  margin: 0.5em 0 2em;
  // font-size: 0.98em;
  border: 1px solid #ffffff57;
  &:focus {
    border-color: $primary-text-color;
  }

  &.--password {
    margin-bottom: 0.7em;
  }
}
.forget_action {
  width: 370px;
  text-align: right;
  margin-bottom: 0.8em;
  font-size: 0.9em;
  opacity: 0.8;
}
.auth_btn {
  box-sizing: border-box;
  width: 370px;
  padding: $sp-md;
  background-color: $theme-bg-color;
  color: $primary-text-color;
  border-radius: $br-sm;
  font-size: $txt-md;
  margin-bottom: $sp-md;
  text-align: center;
  border: none;

  &:hover {
    background-color: $theme-bg-color-hover;
    cursor: pointer;
  }

  &.--google {
    background-color: #4285f4;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #2b5dba;
    }
    .google_icon_box {
      float: left;
      margin: 0.1em 5em 0.1em 0.3em;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.2em;
      border-radius: 0.2em;
      position: absolute;
      left: 0.1em;
      .google_icon {
        width: 1.6em;
      }
    }
  }

  &.--loader {
    background-color: transparent;

    .spinner {
      transform: scale(1.3);
    }
  }
}
.anonymous_btn {
  width: 370px;
  border: 1px solid #fff;
  padding: 10px 0;
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
  margin: 50px 0 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #fff;
    color: $secondary-bg-color-hover;
  }

  .anonymous_btn_icon {
    width: 16px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 450px) {
  .auth_card {
    width: calc(100% - 3em);
    margin: 0 1.5em;
  }
  .auth_input {
    width: 100%;
  }
  .auth_btn {
    width: 100%;
  }

  .anonymous_btn {
    width: 100%;
  }
}
</style>
