<template>
  <datepicker
    class="datepicker-input"
    v-model="time"
    input-class-name="dp-input-styles"
    dark
    time-picker
    placeholder="Time"
    @update:model-value="selectTime"
    :position="'right'"
    :is-24="false"
  />
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  props: ["selectedTime"],
  components: { Datepicker },
  data() {
    return {
      time: null,
    };
  },
  watch: {
    selectedTime: {
      handler(newValue, oldValue) {
        if (this.selectedTime) {
          // console.log(this.selectedTime);
          this.time = this.selectedTime;
        }
      },
      deep: true,
    },
  },
  methods: {
    selectTime() {
      this.$emit("timeSelected", this.time);
    },
  },
};
</script>

<style>
.dp__theme_dark {
  --dp-background-color: #252936;
  --dp-text-color: #ffffff;
  --dp-hover-color: #b6bbc8;
  --dp-hover-text-color: #d3d3de;
  --dp-hover-icon-color: #d3d3de;
  --dp-primary-color: #7e9cff;
  --dp-primary-text-color: #ffffff;
  --dp-secondary-color: #d8d8d8;
  --dp-border-color: #7e9cff00;
  --dp-menu-border-color: #7e9cff94;
  --dp-border-color-hover: #7e9cff;
  --dp-disabled-color: #737373;
  --dp-scroll-bar-background: #212121;
  --dp-scroll-bar-color: #484848;
  --dp-success-color: #00701a;
  --dp-success-color-disabled: #428f59;
  --dp-icon-color: #e0e0e0;
  --dp-danger-color: #e53935;
  --dp-highlight-color: rgba(0, 92, 178, 0.2);
}

.datepicker-input {
  width: 100%;
  border-radius: 10px;
}
.dp-input-styles {
  height: 40px !important;
  border-radius: 10px;
  border: 1px solid #7e9cff99;
}
</style>
