import axios from "axios";
import constants from "@/constants";

const url = `${constants.apiUrl}`;

class CityService {
  static async deletePhoto(name) {
    return await axios.delete(`${url}/images/delete/${name}`, (res, err) => {
      if (res) {
        return res;
      } else {
        return err;
      }
    });
  }
}

export default CityService;
