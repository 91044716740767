<template>
  <create-card>
    <template v-slot:header>
      <!-- content for the header slot -->
      <h2 class="card-header">Event Instructions</h2>
      <p class="card-header-company-name">{{ trek?.trek_name }}</p>
    </template>

    <!-- <p class="section-note">This profile will be visible to your customers</p> -->

    <h3 class="instructions-section-heading"># Things To Carry</h3>
    <div class="instructions-card">
      <div class="includes-item" v-for="(item, i) in thingsToCarry" :key="i">
        <img
          class="includes-item-cross"
          src="@/assets/icons/cross-small.svg"
          alt=""
          @click="removeThingsToCarry(i)"
        />
        <p class="includes-item-text">{{ item }}</p>
      </div>
      <div class="includes-split-input-wrap">
        <input
          @keypress="addThingsToCarry"
          v-model="newThingsToCarry"
          class="includes-add-input"
          type="text"
          placeholder="Enter thing to carry"
          autocomplete="off"
        />
        <div class="includes-add-btn" @click="addThingsToCarry()">
          <img
            src="@/assets/icons/add.svg"
            alt=""
            class="includes-add-btn-icon"
          />
        </div>
      </div>
    </div>

    <h3 class="instructions-section-heading"># Instructions</h3>
    <div class="instructions-card">
      <div class="includes-item" v-for="(item, i) in instructions" :key="i">
        <img
          class="includes-item-cross"
          src="@/assets/icons/cross-small.svg"
          alt=""
          @click="removeInstructionItem(i)"
        />
        <p class="includes-item-text">{{ item }}</p>
      </div>
      <div class="includes-split-input-wrap">
        <input
          @keypress="addInstruction"
          v-model="newIncludeItem"
          class="includes-add-input"
          type="text"
          placeholder="Enter instruction"
          autocomplete="off"
        />
        <div class="includes-add-btn" @click="addInstruction()">
          <img
            src="@/assets/icons/add.svg"
            alt=""
            class="includes-add-btn-icon"
          />
        </div>
      </div>
    </div>

    <h3 class="instructions-section-heading"># Price Includes</h3>
    <div class="instructions-card">
      <div
        class="includes-item"
        v-for="(item, i) in priceIncludedItems"
        :key="i"
      >
        <img
          class="includes-item-cross"
          src="@/assets/icons/cross-small.svg"
          alt=""
          @click="removePriceIncludeItem(i)"
        />
        <p class="includes-item-text">{{ item }}</p>
      </div>
      <div class="includes-split-input-wrap">
        <input
          @keypress="addPriceIncludes"
          v-model="newPriceIncludeItem"
          class="includes-add-input"
          type="text"
          placeholder="Enter item included"
          autocomplete="off"
        />
        <div class="includes-add-btn" @click="addPriceIncludes()">
          <img
            src="@/assets/icons/add.svg"
            alt=""
            class="includes-add-btn-icon"
          />
        </div>
      </div>
    </div>
    <h3 class="instructions-section-heading"># Price Excludes</h3>
    <div class="instructions-card">
      <div
        class="includes-item"
        v-for="(item, i) in priceExcludedItems"
        :key="i"
      >
        <img
          class="includes-item-cross"
          src="@/assets/icons/cross-small.svg"
          alt=""
          @click="removePriceExcludeItem(i)"
        />
        <p class="includes-item-text">{{ item }}</p>
      </div>
      <div class="includes-split-input-wrap">
        <input
          @keypress="addPriceExcludes"
          v-model="newPriceExcludeItem"
          class="includes-add-input"
          type="text"
          placeholder="Enter item included"
          autocomplete="off"
        />
        <div class="includes-add-btn" @click="addPriceExcludes()">
          <img
            src="@/assets/icons/add.svg"
            alt=""
            class="includes-add-btn-icon"
          />
        </div>
      </div>
    </div>

    <h3 class="instructions-section-heading">
      # Booking Instructions <small>(Optional)</small>
    </h3>
    <div class="instructions-card">
      <p class="input-note --warning text-right">Max 250 char</p>
      <textarea
        v-model="bookingInstructions"
        type="text"
        placeholder="Enter booking instruction"
        autocomplete="off"
        maxlength="250"
        rows="4"
      />
    </div>

    <div class="cta-wrap">
      <div class="pill_btn --hollow --left-icon" @click="back()">
        <img class="back-btn-icon" src="@/assets/icons/angle-left.svg" alt="" />
        Back
      </div>
      <div
        class="pill_btn --right-icon"
        :class="{ '--disabled': loadingUpdate }"
        @click="updateInstructions()"
      >
        Next <img src="@/assets/icons/angle-right.svg" alt="" />
      </div>
    </div>
  </create-card>
</template>

<script>
import CreateCard from "@/components/layouts/CreateCard.vue";
import GetterService from "@/services/trek/GetterService";
import TrekSetters from "@/services/trek/SetterServices";
import computedMixin from "@/mixins/computedMixin";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  mixins: [computedMixin],
  components: { CreateCard },
  data() {
    return {
      loadingUpdate: false,
      newIncludeItem: "",
      instructions: [],
      newThingsToCarry: "",
      thingsToCarry: [],
      newPriceIncludeItem: "",
      priceIncludedItems: [],
      newPriceExcludeItem: "",
      priceExcludedItems: [],
      bookingInstructions: null,
    };
  },
  mounted() {
    this.instructions = [
      // "Be prepared: Pack suitable clothing, sturdy footwear, a backpack with essentials, and a reusable water bottle.",
      // "Follow the guide",
      // "Stay hydrated",
      // "Respect nature: Leave no trace, avoid littering, and respect wildlife and their habitats.",
    ];
    this.thingsToCarry = [
      // "Snacks",
      // "Extra pair of clothes",
      // "2/3 liters of water (Mandatory)",
      // "Any personal medicines",
      // "Raincoat",
    ];
    this.priceIncludedItems = [
      // "Travel expenses",
      // "Rappelling fees",
      // "Breakfast / lunch",
      // "First aid supplies",
      // "Forest entry fee",
      // "Professional guide",
    ];
    this.priceExcludedItems = [
      // "Snacks", "Raincoat", "Water"
    ];
    if (this.trekId || this.templateId) {
      this.prefill();
    }
  },
  computed: {
    companyId() {
      return this.$route.params.companyId;
    },
    currentRouteMeta() {
      return this.$route.meta;
    },
    templateId() {
      return this.$route.query.tid;
    },
  },
  methods: {
    back() {
      this.$router.push({
        name: this.currentRouteMeta.preStep,
        params: { companyId: this.companyId },
      });
    },
    async prefill() {
      let trek;
      if (this.templateId) {
        trek = await GetterService.templateById(this.templateId);
      } else {
        trek = await GetterService.publicTrekById(this.trekId);
      }
      this.trek = trek.data.trek;
      console.log(this.trek);
      if (this.trek.trek_things_to_carry.length) {
        this.thingsToCarry = this.trek.trek_things_to_carry;
      }
      if (this.trek.trek_instructions.length) {
        this.instructions = this.trek.trek_instructions;
      }
      if (this.trek.price_includes.length) {
        this.priceIncludedItems = this.trek.price_includes;
      }
      if (this.trek.price_excludes.length) {
        this.priceExcludedItems = this.trek.price_excludes;
      }
      if (this.trek.booking_instructions?.length) {
        this.bookingInstructions = this.trek.booking_instructions;
      }
    },

    addInstruction(e) {
      if (e && e.keyCode == 13) {
        this.instructions.push(this.newIncludeItem);
        this.newIncludeItem = null;
      }
      if (!e) {
        this.instructions.push(this.newIncludeItem);
        this.newIncludeItem = null;
      }
    },
    removeInstructionItem(i) {
      this.instructions.splice(i, 1);
    },
    addThingsToCarry(e) {
      if (e && e.keyCode == 13) {
        this.thingsToCarry.push(this.newThingsToCarry);
        this.newThingsToCarry = null;
      }
      if (!e) {
        this.thingsToCarry.push(this.newThingsToCarry);
        this.newThingsToCarry = null;
      }
    },
    removeThingsToCarry(i) {
      this.thingsToCarry.splice(i, 1);
    },
    addPriceIncludes(e) {
      if (e && e.keyCode == 13) {
        this.priceIncludedItems.push(this.newPriceIncludeItem);
        this.newPriceIncludeItem = null;
      }
      if (!e) {
        this.priceIncludedItems.push(this.newPriceIncludeItem);
        this.newPriceIncludeItem = null;
      }
    },
    removePriceIncludeItem(i) {
      this.priceIncludedItems.splice(i, 1);
    },
    addPriceExcludes(e) {
      if (e && e.keyCode == 13) {
        this.priceExcludedItems.push(this.newPriceExcludeItem);
        this.newPriceExcludeItem = null;
      }
      if (!e) {
        this.priceExcludedItems.push(this.newPriceExcludeItem);
        this.newPriceExcludeItem = null;
      }
    },
    removePriceExcludeItem(i) {
      this.priceExcludedItems.splice(i, 1);
    },
    async updateInstructions() {
      try {
        this.loadingUpdate = true;
        const data = {
          companyId: this.company._id,
          trekId: this.trekId,
          instructions: this.instructions,
          thingsToCarry: this.thingsToCarry,
          priceIncludes: this.priceIncludedItems,
          priceExcludes: this.priceExcludedItems,
          bookingInstructions: this.bookingInstructions,
        };
        const trek = await TrekSetters.updateTrekSection(data, "instructions");
        this.$router.push({
          name: "CreateTrekEvent",
          params: { companyId: this.companyId, trekId: trek._id },
          query: { tid: this.templateId },
        });
        this.loadingUpdate = false;
      } catch (error) {
        toast.error("Something went wrong, please try again!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
        this.loadingUpdate = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.card-header-company-name {
  margin-bottom: 36px;
}

.card-header,
.card-header-company-name {
  text-align: left;
}
.--logo-input-wrap {
  display: flex;
  align-items: center;
  gap: $sp-md;
}
.cta-wrap {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.instructions-section-heading {
  font-size: $txt-lg;
  color: $secondary-text-color;
}

.instructions-card {
  display: flex;
  flex-direction: column;
  gap: $sp-lg;
  margin: $sp-md 0 $sp-xl;
  background-color: $secondary-bg-color;
  padding: $sp-md;
  border-radius: $br-sm;
  .instructions-added-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.--title {
      border-bottom: 1px solid $tertiary-text-color;
      padding-bottom: $sp-sm;
      .instructions-unit,
      .instructions-value {
        font-size: $txt-md;
        font-weight: 600;
      }
    }

    .instructions-row-note {
      color: $secondary-text-color;
    }

    .instructions-unit,
    .instructions-value {
      font-size: $txt-sm;
      font-weight: 300;
    }
    .instructions-value-input {
      width: 100px;
      padding: $sp-sm;
      text-align: right;
    }
  }
}

.includes-item {
  display: flex;
  align-items: center;
  gap: $sp-xxs;

  .includes-item-cross {
    width: $icon-w-xs;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
  .includes-item-text {
    font-size: $txt-sm;
  }
}

.includes-split-input-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: $sp-lg;

  .includes-add-input {
    width: calc(100% - 40px);
    padding: $sp-sm;
  }

  .includes-add-btn {
    width: $dis-30;
    height: $dis-30;
    border-radius: $br-sm;
    background-color: $theme-bg-color;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: $sp-xs;
    cursor: pointer;

    &:hover {
      background-color: $theme-bg-color-hover;
    }

    .includes-add-btn-icon {
      width: $icon-w-sm;
    }
  }
}
@media only screen and (min-width: 600px) {
}
</style>
