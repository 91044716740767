<template>
  <div class="tickets_list-item">
    <h3 class="ticket-heading">
      {{ ticket.ticket_heading }}
      <div class="ticket-actions">
        <img
          src="@/assets/icons/edit.svg"
          alt=""
          class="edit-ticket-btn"
          @click="$emit('editTicket', ticket)"
        />
        <img
          src="@/assets/icons/delete.svg"
          alt=""
          class="edit-ticket-btn"
          @click="$emit('deleteTicket', ticket)"
        />
      </div>
    </h3>
    <div
      class="ticket-pickups"
      v-for="(pickup, j) in ticket.pickup_points"
      :key="j"
    >
      <div class="pickup-point">
        <strong>Pickup point: </strong> {{ `${pickup.address.full_address}` }}
      </div>
      <!-- <div class="pickup-time">
        <strong>Pickup Time: </strong> {{ `${convertDate(pickup.datetime)}` }}
      </div> -->
    </div>
    <h4 class="ticket-price">₹ {{ ticket.price_value }}/-</h4>

    <div class=" " v-show="isTicketSelected" @click.stop>
      <div class="hr my-10"></div>
      <label for="smInput" class="sm-label">Select Pickup Date & Time</label>
      <div class="split_inputs date-time-split my-10 mb-12">
        <date-picker
          @datesSelected="selectDates"
          :selectedDates="date"
        ></date-picker>
        <time-picker
          @timeSelected="selectTime"
          :selectedTime="time"
        ></time-picker>
      </div>
      <div class="input_wrap">
        <label for="smInput" class="sm-label">Available Seats</label>
        <input
          type="number"
          class="my-10"
          v-model="availableTickets"
          placeholder="No. of available seats for this tickets"
        />
      </div>
      <div class="input-note --error" v-if="error">{{ error }}</div>
      <div
        class="pill_btn --small --right-float mt-10"
        :class="{ '--hollow': selectedTicket?.ticket_template_id }"
        @click="selectTicket(ticket)"
      >
        {{
          selectedTicket?.ticket_template_id
            ? "Remove this ticket"
            : "Add this ticket"
        }}
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "@/components/inputs/DatePicker.vue";
import TimePicker from "@/components/inputs/TimePicker.vue";
export default {
  components: { DatePicker, TimePicker },
  props: ["ticket", "isTicketSelected", "selectedTicket"],
  data() {
    return {
      date: null,
      time: null,
      availableTickets: null,
      error: null,
    };
  },
  methods: {
    selectDates(e) {
      if (e) {
        this.date = e;
      } else {
        this.date = null;
      }
    },
    selectTime(e) {
      if (e) {
        this.time = e;
      } else {
        this.time = null;
      }
    },
    convertDate(d) {
      const inputDateString = d;

      const inputDate = new Date(inputDateString);
      const options = {
        weekday: "short", // "Sat"
        day: "2-digit", // "12"
        month: "short", // "Aug"
        year: "numeric", // "2023"
        hour: "numeric", // "10"
        minute: "2-digit", // "30"
        hour12: true, // "P.M."
      };

      const formattedDate = `${inputDate.toLocaleDateString("en-US", options)}`;
      return formattedDate;
    },
    combineDateTime(dateString, timeObject) {
      const date = new Date(dateString);

      // Set hours, minutes, and seconds from the timeObject
      date.setHours(timeObject.hours);
      date.setMinutes(timeObject.minutes);
      date.setSeconds(timeObject.seconds);

      return date;
    },
    selectTicket() {
      if (this.selectedTicket?.ticket_template_id) {
        const ticket = {
          ticket_template_id: this.ticket._id,
          ticket: this.ticket,
          available_tickets: this.availableTickets,
        };
        this.$emit("ticketSelected", ticket);
      } else if (this.date && this.time && this.availableTickets) {
        const ticket = {
          ticket_template_id: this.ticket._id,
          pickup_datetime: this.combineDateTime(this.date, this.time),
          ticket: this.ticket,
          available_tickets: this.availableTickets,
        };
        this.$emit("ticketSelected", ticket);
        this.error = null;
      } else {
        this.error = "Select Date, Time & Available Seats";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.tickets_list-item {
  background-color: $secondary-bg-color;
  padding: $sp-sm $sp-md;
  border-radius: $br-sm;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: $sp-md;
  gap: $sp-sm;

  &:hover {
    background-color: $secondary-bg-color-hover;
  }
}
.ticket-heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $txt-lg;

  .ticket-actions {
    display: flex;
    align-items: center;
    gap: $sp-lg;
    .edit-ticket-btn {
      width: $icon-w-sm;
      height: $icon-w-sm;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}
.ticket-pickups {
  font-size: $txt-sm;
  color: $primary-text-color;

  strong {
    color: $secondary-text-color;
  }
}
.ticket-price {
  font-size: $txt-md;
}
</style>
