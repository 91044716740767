import axios from "axios";
export default {
  methods: {
    async getCountries() {
      const options = {
        method: "GET",
        url: "https://wft-geo-db.p.rapidapi.com/v1/geo/countries",
        params: { limit: "5", namePrefix: this.countryName },
        headers: {
          "X-RapidAPI-Key":
            "7fc6b87e92mshd1c3ad9884b4d7ap19a3b6jsn8304e2975ca7",
          "X-RapidAPI-Host": "wft-geo-db.p.rapidapi.com",
        },
      };

      await axios
        .request(options)
        .then((response) => {
          this.countries = response.data.data;
        })
        .catch(function (error) {
          // console.error(error);
          return error;
        });
    },

    async getStates() {
      const options = {
        method: "GET",
        url: `https://wft-geo-db.p.rapidapi.com/v1/geo/countries/${this.country.code}/regions`,
        params: { limit: "5", namePrefix: this.stateName },
        headers: {
          "X-RapidAPI-Key":
            "7fc6b87e92mshd1c3ad9884b4d7ap19a3b6jsn8304e2975ca7",
          "X-RapidAPI-Host": "wft-geo-db.p.rapidapi.com",
        },
      };

      await axios
        .request(options)
        .then((response) => {
          this.states = response.data.data;
        })
        .catch(function (error) {
          // console.error(error);
          return error;
        });
    },

    async getCities() {
      const options = {
        method: "GET",
        url: `https://wft-geo-db.p.rapidapi.com/v1/geo/countries/${this.country.code}/regions/${this.state.isoCode}/cities`,
        params: { limit: "5", namePrefix: this.cityName },
        headers: {
          "X-RapidAPI-Key":
            "7fc6b87e92mshd1c3ad9884b4d7ap19a3b6jsn8304e2975ca7",
          "X-RapidAPI-Host": "wft-geo-db.p.rapidapi.com",
        },
      };

      await axios
        .request(options)
        .then((response) => {
          this.cities = response.data.data;
        })
        .catch(function (error) {
          // console.error(error);
          return error;
        });
    },
  },
};
