<template>
  <section>
    <!-- <nav-bar></nav-bar> -->
    <div class="company-view-layout">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue";
import GetterService from "@/services/company/GetterServices";
import computedMixin from "@/mixins/computedMixin";

export default {
  mixins: [computedMixin],
  // components: { NavBar },
  computed: {
    companyId() {
      return this.$route.params.companyId;
    },
  },
  mounted() {
    this.getProfile();
  },
  beforeUnmount() {
    localStorage.removeItem("currentCompany");
    this.company = null;
  },
  methods: {
    async getProfile() {
      let company = await GetterService.companyById(this.companyId);
      localStorage.setItem("currentCompany", JSON.stringify(company.data));
      this.company = company.data;
    },
  },
};
</script>

<style lang="scss" scoped>
// .company-view-layout {
//   padding-top: 46px;
//   box-sizing: border-box;
// }

// @media (min-width: 600px) {
//   .company-view-layout {
//     padding-top: 60px;
//   }
// }
</style>
