<template>
  <create-card>
    <template v-slot:header>
      <!-- content for the header slot -->
      <h2 class="card-header">Event Details</h2>
      <p class="card-header-company-name">{{ trek?.trek_name }}</p>
    </template>

    <!-- <p class="section-note">This profile will be visible to your customers</p> -->

    <div class="input_wrap">
      <h3 for="smInput" class="">Select event date</h3>
      <toggle-input
        class="my-15 mb-8"
        :dark="false"
        :label="'Allow custom dates'"
        :toggleState="customDatesAvailable"
        @toggled="
          (e) => {
            customDatesAvailable = e;
          }
        "
      ></toggle-input>
      <p class="input-note">Let customer select any date</p>
    </div>
    <h4>OR</h4>
    <div
      class="input_wrap my-20"
      :class="{ '--disabled': customDatesAvailable }"
    >
      <multi-dates-picker
        class="my-10"
        @datesSelected="selectDates"
        :selectedDates="eventDates"
      ></multi-dates-picker>
      <!-- <date-picker
        class="my-10"
        @datesSelected="selectDates"
        :selectedDates="eventDate"
      ></date-picker> -->
    </div>

    <div class="tickets">
      <div class="tickets_list">
        <h3 class="ticket-list-heading">Event Packages</h3>
        <div class="no-tickets" v-if="!tickets || !tickets.length">
          <h5 class="text-center">No package added!</h5>
          <p class="input-note text-center">Atleast 1 package is required</p>
        </div>
        <div
          class="ticket_list-item-wrap"
          v-for="(ticket, i) in tickets"
          :key="i"
        >
          <ticket-card
            :ticket="ticket"
            @editTicket="initEditTicket"
            @deleteTicket="initDeleteTicket"
          ></ticket-card>
        </div>
      </div>

      <div class="add-ticket">
        <div
          class="add-ticket-btn pill_btn --hollow --small --left-icon"
          @click="addNewTicket = !addNewTicket"
        >
          <img src="@/assets/icons/add.svg" alt="" />
          Add new package
        </div>
      </div>
      <DrawerLayout
        :toggleDrawer="addNewTicket"
        @closeDrawer="addNewTicket = false"
      >
        <NewTicket
          :editTicket="editTicket"
          :templateId="templateId"
          v-if="addNewTicket"
          @ticketAdded="addNewCreatedTicket"
          @cancelAdd="addNewTicket = false"
        ></NewTicket>
      </DrawerLayout>
    </div>

    <div class="input_wrap my-20">
      <label for="advancePayment">Advance payment (in %)</label>
      <input
        type="number"
        v-model="advancePayment"
        id="advancePayment"
        placeholder="e.g. 20"
      />
    </div>

    <div class="warn-checkbox-wrap input_wrap">
      <input id="warn-checkbox" type="checkbox" v-model="checkedWarn" />
      <label for="warn-checkbox" :class="{ '--unselected': !checkedWarn }"
        >(Check) Make sure to check event date & pickup date are correct</label
      >
    </div>
    <div class="cta-wrap">
      <div class="pill_btn --hollow --left-icon" @click="back()">
        <img class="back-btn-icon" src="@/assets/icons/angle-left.svg" alt="" />
        Back
      </div>
      <div
        class="pill_btn --right-icon"
        :class="{
          '--disabled':
            loadingUpdate ||
            !tickets?.length ||
            (!eventDates?.length && !customDatesAvailable) ||
            !checkedWarn,
        }"
        @click="updateEvent()"
      >
        Next <img src="@/assets/icons/angle-right.svg" alt="" />
      </div>
    </div>
  </create-card>
</template>

<script>
import CreateCard from "@/components/layouts/CreateCard.vue";
import GetterService from "@/services/trek/GetterService";
import TrekSetters from "@/services/trek/SetterServices";
import computedMixin from "@/mixins/computedMixin";
import NewTicket from "@/components/create/trek//NewTicket.vue";
import DrawerLayout from "@/components/common/directives/DrawerLayout.vue";
import TicketCard from "@/components/create/trek/TicketCard.vue";
// import DatePicker from "@/components/inputs/DatePicker.vue";
import MultiDatesPicker from "@/components/inputs/MultiDates.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import ToggleInput from "@/components/inputs/ToggleInput.vue";

export default {
  mixins: [computedMixin],
  components: {
    CreateCard,
    NewTicket,
    DrawerLayout,
    TicketCard,
    ToggleInput,
    // DatePicker,
    MultiDatesPicker,
  },
  data() {
    return {
      loadingUpdate: false,
      tickets: [],
      loadingCreate: false,
      addNewTicket: false,
      eventDate: null,
      eventDates: [],
      customDatesAvailable: false,
      editTicket: null,
      advancePayment: null,
      checkedWarn: false,
    };
  },
  mounted() {
    if (this.trekId || this.templateId) {
      this.prefill();
    }
  },
  computed: {
    trekId() {
      return this.$route.params.trekId;
    },
    templateId() {
      return this.$route.query.tid;
    },
    companyUsername() {
      return this.$route.params.companyUsername;
    },
    currentRouteMeta() {
      return this.$route.meta;
    },
  },
  methods: {
    async prefill() {
      let trek;
      if (this.templateId) {
        trek = await GetterService.templateById(this.templateId);
      } else {
        trek = await GetterService.publicTrekById(this.trekId);
      }
      this.trek = trek.data.trek;
      if (this.trek.tickets?.length) {
        this.tickets = this.trek.tickets;
      }
      if (this.trek.event_dates) {
        this.trek?.event_dates?.forEach((d) => {
          this.eventDates?.push(this.getDateWithoutTime(d));
        });
      }
      if (this.trek.custom_dates_available) {
        this.customDatesAvailable = this.trek.custom_dates_available;
      }
      if (this.trek.advance_payment) {
        this.advancePayment = this.trek.advance_payment;
      }
    },
    back() {
      this.$router.push({
        name: this.currentRouteMeta.preStep,
        params: { companyId: this.companyUsername },
      });
    },
    getDateWithoutTime(dateTimeString) {
      const date = new Date(dateTimeString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    initEditTicket(ticket) {
      this.editTicket = ticket;
      this.addNewTicket = true;
    },
    initDeleteTicket(ticket) {
      if (this.tickets.length > 1) {
        if (confirm(`Do you want to delete ${ticket.ticket_heading} ticket`)) {
          const index = this.tickets.findIndex((t) => t._id == ticket._id);
          this.tickets.splice(index, 1);
        }
      } else {
        alert("Atleast one ticket is required");
      }
    },
    addNewCreatedTicket(ticket) {
      this.addNewTicket = false;
      // console.log(ticket, "added");
      if (this.editTicket) {
        const foundTicketIndex = this.tickets.findIndex((t) => {
          return t._id == ticket._id;
        });
        this.tickets.splice(foundTicketIndex, 0, ticket);
        this.tickets.splice(foundTicketIndex + 1, 1);
        this.editTicket = null;
      } else {
        this.tickets.splice(0, 0, ticket);
      }
    },

    selectDates(e) {
      if (e) {
        this.eventDates = e;
      } else {
        this.eventDates = null;
      }
    },
    async updateEvent() {
      try {
        this.loadingUpdate = true;
        const data = {
          companyId: this.company._id,
          trekId: this.trekId,
          eventDates: this.eventDates,
          customDatesAvailable: this.customDatesAvailable,
          tickets: this.tickets,
          advancePayment: this.advancePayment,
        };
        const trek = await TrekSetters.updateTrekSection(data, "event");
        this.$router.push({
          name: "TrekDashboard",
          params: { companyId: this.companyId, trekId: trek._id },
          query: { tid: this.templateId },
        });
        this.loadingUpdate = false;
        console.log(trek, "updated");
      } catch (error) {
        toast.error("Something went wrong, please try again!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
        this.loadingUpdate = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.card-header-company-name {
  margin-bottom: 36px;
}

.card-header,
.card-header-company-name {
  text-align: left;
}
.--logo-input-wrap {
  display: flex;
  align-items: center;
  gap: $sp-md;
}
.cta-wrap {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tickets {
  margin-top: $sp-xl;
  .tickets_list {
    margin-bottom: $sp-lg;

    .ticket-list-heading {
      margin-bottom: $sp-lg;
    }
  }
}

.add-ticket {
  display: flex;
  justify-content: center;
}

.warn-checkbox-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: $sp-xs;
  font-size: $txt-sm;
  cursor: pointer;

  .--unselected {
    color: $error-bg-color;
  }

  input {
    width: $icon-w-sm;
    height: $icon-w-sm;
    cursor: pointer;
  }
  label {
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) {
}
</style>
