<template>
  <div class="dashboard-right-wrap">
    <div class="section-breadcrumb" @click="$router.back()">
      <img src="@/assets/icons/angle-left.svg" alt="" class="back-icon" />
      <h4 class="breadcrumb-item">Booking:</h4>
      <h4 class="breadcrumb-item --current">{{ booking?.booking_id }}</h4>
    </div>
    <div
      v-show="loadingBooking"
      class="shimmers"
      v-for="s in loadingBooking * 4"
      :key="s"
    ></div>
    <div class="booking-card" v-if="booking">
      <h3 class="heading-lg">{{ trek?.trek_name }}</h3>
      <h4 class="heading-md">
        Booking Date:
        <strong>
          {{ convertDate(booking.event_date) }}
        </strong>
      </h4>
    </div>
    <div class="booking-card" v-if="booking">
      <h3 class="heading-lg">{{ booking?.customer.customer_name }}</h3>
      <h5 class="content-sm">{{ booking?.customer.contact_email }}</h5>
      <h5 class="content-sm">{{ booking?.customer.contact_no }}</h5>
      <div class="contact-btns">
        <a
          :href="`https://wa.me/91${booking.customer.contact_no}`"
          class="pill_btn --small --hollow --left-icon"
        >
          <img
            src="@/assets/icons/whatsapp.svg"
            alt=""
            class="contact-icon"
          />Whatsapp
        </a>
        <a
          :href="`tel:${booking.customer.contact_no}`"
          class="pill_btn --small --hollow --left-icon"
        >
          <img src="@/assets/icons/call.svg" alt="" class="contact-icon" />Call
        </a>
      </div>
      <h3 class="heading-md my-15">{{ booking?.booking_id }}</h3>
      <h4 class="h6 heading-sm">Status: {{ booking?.booking_status }}</h4>
    </div>

    <div class="booking-card" v-if="booking">
      <h3 class="heading-lg">
        <span>Seats: ( {{ booking?.seats.length }} seats )</span>
      </h3>
      <div class="hr"></div>
      <div class="seats-table">
        <div class="seat-row" v-for="seat in booking?.seats" :key="seat._id">
          <p class="seat-name content-sm">
            {{ seat.name }}
          </p>
          <p class="seat-info content-sm">
            {{ seat.age }}
            {{ seat.gender }}
          </p>
        </div>
      </div>
    </div>

    <div class="booking-card" v-if="booking">
      <h3 class="heading-lg">
        Payment
        <div
          class="pill_btn --small"
          :class="{ '--disabled': loadUpdate }"
          v-if="booking?.booking_status != 'booked'"
          @click="changeStatus(booking._id, 'booked', bi)"
        >
          Mark Paid
        </div>
        <h4 class="payment-status" v-if="booking?.booking_status == 'booked'">
          {{ booking.payment_status }}
        </h4>
      </h3>
      <div class="hr"></div>
      <div class="price-table">
        <div class="price-row">
          <p class="content-sm">Price per person</p>
          <p class="content-sm">{{ booking?.price.price_value }}</p>
        </div>
        <div class="price-row">
          <p class="content-sm">Seats</p>
          <p class="content-sm">{{ booking?.seats.length }}</p>
        </div>
        <div class="hr --dashed m-0"></div>
        <div class="price-row">
          <p class="heading-md">Total</p>
          <p class="heading-md">
            {{ booking?.price.price_value * booking?.seats.length }}
          </p>
        </div>
        <div class="price-row">
          <p class="heading-md">Paid</p>
          <p class="heading-md">
            {{ booking?.paid_amount }}
          </p>
        </div>
        <div class="price-row">
          <p class="heading-md">Remaining</p>
          <p class="heading-md">
            {{
              booking?.price.price_value * booking?.seats.length -
              booking?.paid_amount
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BookingGetters from "@/services/bookings/GetterService";
import BookingSetters from "@/services/bookings/SetterService";
import computedMixin from "@/mixins/computedMixin";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  mixins: [computedMixin],
  data() {
    return {
      booking: null,
      loadingBooking: false,
      loadUpdate: false,
    };
  },
  computed: {
    bookingId() {
      return this.$route.query.bid;
    },
  },
  mounted() {
    this.getBooking();
  },
  methods: {
    async getBooking() {
      try {
        this.loadingBooking = true;
        let booking = await BookingGetters.getBookingsById(this.bookingId);
        this.booking = booking;
        this.loadingBooking = false;
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    },
    convertDate(d) {
      const date = new Date(d); // Replace this with your desired date

      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };

      const formattedDate = date.toLocaleDateString("en-US", options);
      return formattedDate;
    },
    async changeStatus(id, status, index) {
      try {
        this.loadUpdate = true;
        let updatedBooking = await BookingSetters.changeStatus(id, status);
        this.booking = updatedBooking;
        this.loadUpdate = false;
      } catch (error) {
        toast.error("Something went wrong!", {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
      // this.bookings.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.shimmers {
  height: 180px; /* Set the desired height of your card */
  border-radius: $br-sm;
  overflow: hidden;
  background-color: $secondary-bg-color-hover;
  margin: $sp-md 0;

  /* Apply the shimmer effect */
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, transparent, #070a123b, transparent);
    animation: shimmer 2s infinite;
  }

  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}

.section-breadcrumb {
  display: flex;
  align-items: center;
  gap: $sp-md;
  padding: $sp-sm 0;
  cursor: pointer;
  .back-icon {
    width: $icon-w-sm;
    cursor: pointer;
  }

  .breadcrumb-item {
    color: $secondary-text-color;

    &.--current {
      color: $primary-text-color;
    }
  }
}

.booking-card {
  background-color: $secondary-bg-color;
  border-radius: $br-sm;
  padding: $sp-md;
  margin: $sp-md 0;
}
.heading-lg {
  font-size: $txt-lg;
  font-weight: 600;
  margin-bottom: $sp-sm;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.heading-md {
  font-size: $txt-md;
  font-weight: 400;
  margin-bottom: $sp-xs;
}
.heading-sm {
  font-size: $txt-sm;
  font-weight: 600;
  margin-bottom: $sp-xs;
  text-transform: capitalize;
}
.content-sm {
  font-size: $txt-sm;
  font-weight: 400;
  margin-bottom: $sp-xxs;
}
.contact-btns {
  display: flex;
  align-items: center;
  gap: $sp-md;
}

.seats-table {
  display: flex;
  flex-direction: column;
  gap: $sp-md;

  .seat-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.payment-status {
  font-size: $txt-md;
  text-transform: capitalize;
}
.price-table {
  display: flex;
  flex-direction: column;
  gap: $sp-md;

  .price-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
