<template>
  <home-view>
    <template v-slot:sidebar>
      <!-- SIDEBAR COMPANY NAME HEADER -->
      <!-- <h3 class="sidebar-title">
        <uppy-photo
          v-if="company?.logo"
          class="sidebar-title-logo"
          :imgName="company?.logo"
          :key="company?.logo"
        ></uppy-photo>
        <img
          v-else
          class="sidebar-title-logo"
          src="@/assets/imgs/dummy-trek/trek-logo.png"
          alt=""
        />
        {{ company?.company_name || "Company name..." }}
      </h3> -->
      <!-- SIDEBAR COMPANY NAME HEADER -->

      <!-- SIDEBAR OPTIONS-->
      <div class="sidebar-items">
        <div
          class="sidebar-item"
          @click="changeSection('events')"
          :class="{ '--active-section': activeSection == 'events' }"
        >
          <img
            class="sidebar-item-icon"
            src="@/assets/icons/calendar.svg"
            alt=""
          />
          Events List
        </div>
        <div
          class="sidebar-item"
          @click="changeSection('categories')"
          :class="{ '--active-section': activeSection == 'categories' }"
        >
          <img
            class="sidebar-item-icon"
            src="@/assets/icons/calendar.svg"
            alt=""
          />
          Category List
        </div>
        <div
          class="sidebar-item"
          @click="changeSection('customers')"
          :class="{ '--active-section': activeSection == 'customers' }"
        >
          <img
            class="sidebar-item-icon"
            src="@/assets/icons/users.svg"
            alt=""
          />
          Customers
        </div>
        <div
          v-if="company?.company_username == 'smitaholidays'"
          class="sidebar-item"
          @click="changeSection('inquiries')"
          :class="{ '--active-section': activeSection == 'inquiries' }"
        >
          <img
            class="sidebar-item-icon"
            src="@/assets/icons/users.svg"
            alt=""
          />
          Inquiries
        </div>
        <div
          class="sidebar-item"
          @click="changeSection('profile')"
          :class="{ '--active-section': activeSection == 'profile' }"
        >
          <img class="sidebar-item-icon" src="@/assets/icons/user.svg" alt="" />
          Company Profile
        </div>
        <div
          class="sidebar-item"
          @click="
            changeSection('settings');
            $router.push({
              name: 'CreateNewCompany',
              query: { companyId: company._id },
            });
          "
          :class="{ '--active-section': activeSection == 'settings' }"
        >
          <img
            class="sidebar-item-icon"
            src="@/assets/icons/settings.svg"
            alt=""
          />
          Company Settings
        </div>
        <div
          class="sidebar-item"
          @click="$router.push({ name: 'UserDashboard' })"
          :class="{ '--active-section': activeSection == 'companies' }"
        >
          <img
            class="sidebar-item-icon"
            src="@/assets/icons/companies.svg"
            alt=""
          />
          Companies List
        </div>
      </div>
      <div
        class="--logout"
        @click="
          changeSection('logout');
          logout();
        "
        :class="{ '--active-section': activeSection == 'logout' }"
      >
        <img
          class="sidebar-item-icon"
          src="@/assets/icons/sign-out.svg"
          alt=""
        />
        Log out
      </div>
      <!-- SIDEBAR OPTIONS-->
    </template>

    <template v-slot:content>
      <!-- COMPANY PROFILE -->
      <div class="dashboard-left" v-if="activeSection == 'profile'">
        <div class="dashboard__org-wrapper" v-if="company">
          <CompanyProfile :company="company"></CompanyProfile>
          <div class="open-profile"></div>
        </div>
      </div>
      <!-- COMPANY PROFILE -->

      <!-- CUSTOMERS LIST -->
      <div
        class="dashboard-right"
        v-if="activeSection == 'customers' && !customerDetailsId"
      >
        <div class="dashboard-rigth-wrap">
          <!-- NAVBAR -->
          <h3 class="section-heading">Customers List</h3>
          <!-- NAVBAR -->

          <!-- CUSTOMER LIST -->
          <div class="dashboard-treks-wrap">
            <CustomersList
              v-if="company"
              :company="company"
              :key="company._id"
            ></CustomersList>
          </div>
          <!-- CUSTOMER LIST -->
        </div>
      </div>
      <!-- CUSTOMERS LIST -->

      <!-- CUSTOMER DETAILS -->
      <div class="dashboard-right" v-if="customerDetailsId">
        <div class="dashboard-rigth-wrap">
          <!-- CUSTOMER DETAILS -->
          <div class="dashboard-treks-wrap">
            <CustomerDetails></CustomerDetails>
          </div>
          <!-- CUSTOMER DETAILS -->
        </div>
      </div>
      <!-- CUSTOMER DETAILS -->

      <!-- INQUIRY LIST -->
      <div
        class="dashboard-right"
        v-if="activeSection == 'inquiries' && !inquiryId"
      >
        <div class="dashboard-rigth-wrap">
          <!-- NAVBAR -->
          <h3 class="section-heading">Inquiries List</h3>
          <!-- NAVBAR -->
          <!-- EVENT FILTER OPTIONS -->
          <div class="dashboard-right__nav">
            <div
              class="nav-item"
              :class="{ '--active': activeInquiryTab == 'recieved' }"
              @click="changeInquiryActiveTab('recieved')"
            >
              Recieved
            </div>
            <div
              class="nav-item"
              :class="{ '--active': activeInquiryTab == 'in-process' }"
              @click="changeInquiryActiveTab('in-process')"
            >
              In Process
            </div>
            <div
              class="nav-item"
              :class="{ '--active': activeInquiryTab == 'settled' }"
              @click="changeInquiryActiveTab('settled')"
            >
              Settled
            </div>
          </div>
          <!--EVENT FILTER OPTIONS -->

          <!-- CUSTOMER LIST -->
          <div class="dashboard-treks-wrap">
            <InquiriesList
              v-if="company"
              :company="company"
              :key="company._id"
              :tab="activeInquiryTab"
            ></InquiriesList>
          </div>
          <!-- CUSTOMER LIST -->
        </div>
      </div>
      <!-- INQUIRY LIST -->

      <!-- INQUIRY DETAILS -->
      <div class="dashboard-right" v-if="inquiryId">
        <div class="dashboard-rigth-wrap">
          <!-- INQUIRY DETAILS -->
          <div class="dashboard-treks-wrap">
            <InquiryDetails></InquiryDetails>
          </div>
          <!-- INQUIRY DETAILS -->
        </div>
      </div>
      <!-- INQUIRY DETAILS -->

      <!-- EVENTS LIST -->
      <div class="dashboard-right" v-if="activeSection == 'events'">
        <div class="dashboard-rigth-wrap">
          <!-- EVENT NAVBAR -->
          <h3 class="section-heading">Events</h3>
          <!-- EVENT FILTER OPTIONS -->
          <div class="dashboard-right__nav">
            <div
              class="nav-item"
              :class="{ '--active': activeTab == 'all' }"
              @click="changeActiveTab('all')"
            >
              All Events
            </div>
            <div
              class="nav-item"
              :class="{ '--active': activeTab == 'upcoming' }"
              @click="changeActiveTab('upcoming')"
            >
              Upcoming
            </div>
            <div
              class="nav-item"
              :class="{ '--active': activeTab == 'past' }"
              @click="changeActiveTab('past')"
            >
              Past
            </div>
          </div>
          <!--EVENT FILTER OPTIONS -->
          <!-- EVENT NAVBAR -->

          <!-- EVENT LIST -->
          <div class="dashboard-treks-wrap">
            <TreksList
              v-if="company"
              :tab="activeTab"
              :company="company"
              :key="company._id && activeTab"
            ></TreksList>
          </div>
          <!-- EVENT LIST -->
        </div>

        <!-- CREATE BTN -->
        <div class="content-add-btn-wrap">
          <div
            class="content-add-btn"
            v-if="activeSection === 'events'"
            @click="initCreateTrek()"
          >
            <img
              src="@/assets/icons/add.svg"
              alt=""
              class="add-btn-circle-icon"
            />
            Add Event
          </div>
        </div>
        <!-- CREATE BTN -->
      </div>
      <!-- EVENTS LIST -->

      <!-- CATEGORIES LIST -->
      <div class="dashboard-right" v-if="activeSection == 'categories'">
        <div class="dashboard-rigth-wrap">
          <!-- NAVBAR -->
          <h3 class="section-heading">Event Categories</h3>
          <!-- NAVBAR -->

          <!-- CATEGORY LIST -->
          <div class="dashboard-treks-wrap">
            <CategoryList
              v-if="company"
              :tab="activeTab"
              :company="company"
              :key="company._id && activeTab"
            ></CategoryList>
          </div>
          <!-- CATEGORY LIST -->

          <!-- CREATE BTN -->
          <div class="content-add-btn-wrap">
            <div
              class="content-add-btn"
              v-if="activeSection === 'categories'"
              @click="$router.push({ name: 'CreateCategory' })"
            >
              <img
                src="@/assets/icons/add.svg"
                alt=""
                class="add-btn-circle-icon"
              />
              Add Category
            </div>
          </div>
        </div>
      </div>
    </template>
  </home-view>
</template>

<script>
import { getAuth } from "firebase/auth";
import HomeView from "@/components/layouts/HomeView.vue";
import CompanyProfile from "@/components/dashboard/company/CompanyProfile.vue";
import computedMixin from "@/mixins/computedMixin";
import TreksList from "@/components/dashboard/company/TreksList.vue";
import UppyPhoto from "@/components/common/UppyPhoto.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import CustomersList from "@/components/dashboard/company/CustomersList.vue";
import InquiriesList from "@/components/dashboard/company/InquiriesList.vue";
import CustomerDetails from "@/components/dashboard/company/CustomerDetails.vue";
import InquiryDetails from "@/components/dashboard/company/InquiryDetails.vue";
import CategoryList from "@/components/dashboard/company/CategoryList.vue";
export default {
  mixins: [computedMixin],
  components: {
    CompanyProfile,
    TreksList,
    CustomersList,
    InquiriesList,
    HomeView,
    CustomerDetails,
    InquiryDetails,
    CategoryList,
    // UppyPhoto,
  },
  data() {
    return {
      // company: null,
      activeSection: "events",
      activeTab: "upcoming",
      activeInquiryTab: "recieved",
    };
  },
  computed: {
    companyId() {
      return this.$route.params.companyId;
    },
    customerDetailsId() {
      return this.$route.query.cid;
    },
    inquiryId() {
      return this.$route.query.iid;
    },
  },
  mounted() {
    if (this.$route.query.section) {
      this.activeSection = this.$route.query.section;
    }
    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab;
    } else {
      this.$router.push({
        query: { tab: this.activeTab, section: this.activeSection },
      });
    }
  },
  methods: {
    initCreateTrek() {
      this.$router.push({
        name: "CreateOptions",
        params: { companyId: this.company?._id },
      });
    },
    changeSection(sectionName) {
      this.activeSection = sectionName;
      this.$router.push({
        name: "CompanyDashboard",
        query: { section: sectionName },
      });
    },
    changeActiveTab(tab) {
      this.activeTab = tab;
      this.$router.push({ name: "CompanyDashboard", query: { tab: tab } });
    },
    changeInquiryActiveTab(tab) {
      this.activeInquiryTab = tab;
      this.$router.push({
        name: "CompanyDashboard",
        query: { section: "inquiries", tab: tab },
      });
    },
    logout() {
      const auth = getAuth();
      auth.signOut().then(
        () => {
          this.$router.push("/auth/login");
        },
        function (error) {
          toast.error(error, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.content-add-btn-wrap {
  position: fixed;
  bottom: $sp-md;
  right: $sp-md;
  display: flex;
  justify-content: flex-end;
  padding: $sp-md 0;

  .content-add-btn {
    width: fit-content;
    padding: $sp-sm;
    padding-right: $sp-md;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $sp-sm;
    background-color: $theme-bg-color;
    box-shadow: 0 0 25px $shadow-color-xl;
    font-size: $txt-sm;
    cursor: pointer;

    &:hover {
      background-color: $theme-bg-color-hover;
    }

    .add-btn-circle-icon {
      width: $icon-w-md;
    }
  }
}

.sidebar-title {
  padding: $sp-md;
  box-sizing: border-box;
  border-bottom: 1px solid $primary-bg-color;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: $txt-sm;
  display: flex;
  align-items: center;
  gap: $sp-sm;

  .sidebar-title-logo {
    width: 24px;
    height: 24px;
    border-radius: 50px;
    object-fit: cover;
  }
}

.sidebar-items {
  margin-top: $sp-sm;
  display: flex;
  flex-direction: column;
  // gap: $sp-sm;

  .sidebar-item {
    padding: $sp-lg $sp-md;
    box-sizing: border-box;
    font-size: $txt-sm;
    display: flex;
    align-items: center;
    gap: $sp-sm;
    color: $secondary-text-color;
    cursor: pointer;

    .sidebar-item-icon {
      width: $icon-w-xs;
      opacity: 0.6;
    }

    &:hover {
      background-color: $secondary-bg-color-hover;
    }

    &.--active-section {
      background-color: $primary-bg-color;
      color: $primary-text-color;

      .sidebar-item-icon {
        opacity: 1;
      }
    }
  }
}

.--logout {
  padding: $sp-lg $sp-lg;
  box-sizing: border-box;
  font-size: $txt-sm;
  display: flex;
  align-items: center;
  gap: $sp-sm;
  color: $primary-text-color;
  border-radius: $br-sm;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  width: 100%;

  .sidebar-item-icon {
    width: $icon-w-xs;
  }
  &:hover {
    background-color: $error-bg-color-hover;
  }

  &.--active-section {
    background-color: $error-bg-color;
    color: $primary-text-color;

    .sidebar-item-icon {
      opacity: 1;
    }
  }
}

// _____________CONTENT ___________________
.dashboard-left {
  width: calc(100%);
  position: relative;
  padding: 0 0 $sp-lg;
  box-sizing: border-box;
}

.dashboard-right {
  // padding-top: $sp-xxl;
  padding: 0 $sp-md $sp-lg;
  box-sizing: border-box;
  //overflow: auto;
  width: calc(100%);
  position: relative;
  // width: ;

  .section-heading {
    background-color: $secondary-bg-color-hover;
    padding: $sp-sm $sp-md;
    border-radius: $br-xs $br-xs 0 0;
    position: sticky;
    top: 0;
    box-shadow: 0 -25px 25px 20px $shadow-color-xxl;
    z-index: 2;
  }

  .dashboard-right__nav {
    position: sticky;
    top: 3px;
    width: calc(100%);
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: $secondary-bg-color;
    border-radius: $br-xs;
    overflow: hidden;
    z-index: 99;
    box-shadow: 0 0 10px 10px $shadow-color-md;

    .nav-item {
      padding: $sp-sm 0;
      width: 33.33%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 2px solid transparent;
      font-size: $txt-xs;
      cursor: pointer;

      &.--active {
        background-color: $secondary-bg-color-hover;
        border-color: $theme-bg-color;
      }
    }
  }
}

// _____________CONTENT ___________________

// for tablet
@media (min-width: 600px) {
  .content-add-btn-wrap {
    bottom: $sp-lg;
    right: $sp-lg;

    .content-add-btn {
      padding: $sp-md;
      padding-right: $sp-lg;

      .add-btn-circle-icon {
        width: $icon-w-md;
      }
    }
  }

  .sidebar-title {
    border-bottom: 1px solid $secondary-bg-color;
  }

  .sidebar-items {
    .sidebar-item {
      &.--active-section {
        background-color: $secondary-bg-color;
      }
    }
  }

  .dashboard-left {
    padding-bottom: $sp-lg;
    border-radius: $br-sm;
    overflow: hidden;

    .dashboard__org-wrapper {
      width: 100%;
    }
  }

  .dashboard-right {
    width: calc(100%);
    padding: 0 0 $sp-lg;

    .dashboard-right__nav {
      .nav-item {
        padding: $sp-sm 0;

        font-size: $txt-sm;
      }
    }
  }
}
</style>
