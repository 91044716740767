<template>
  <div
    class="dropdown_box"
    tabindex="0"
    @keydown.down.prevent="handleArrowDown"
    @keydown.up.prevent="handleArrowUp"
    @keydown.enter.prevent="emitSelected"
  >
    <input
      ref="dropdownBox"
      v-if="searchBox"
      class="dropdown_input"
      type="text"
      @click.stop
    />
    <input
      v-model="searchText"
      ref="dropdownBox"
      class="dropdown_input"
      type="text"
      placeholder="Search"
      @keyup="this.searchOption(searchText)"
      @click.stop
      v-if="searchBox"
    />
    <div
      class="dropdown"
      :class="{
        '--search_box': searchBox,
      }"
    >
      <div
        class="dropdown_item"
        :class="{
          '--selected': selectedOptions.includes(option),
          '--highlighted': highlightedIndex === index,
        }"
        v-for="(option, index) in filteredOptions"
        :key="option.id || index"
        @click.stop="selectOption(option)"
      >
        {{ option.label || option }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DropDown",
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
    selectedOps: {
      type: Array,
      default: () => [],
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    searchBox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dropdownOptions: [],
      selectedOptions: [],
      searchText: "",
      filteredOptions: [],
      highlightedIndex: 0, // To track keyboard navigation
    };
  },
  mounted() {
    this.dropdownOptions = this.options;
    this.filteredOptions = this.dropdownOptions;

    // Filter duplicates in selectedOps
    this.selectedOptions = this.selectedOps.filter(
      (value, index, self) =>
        value?.label &&
        index ===
          self.findIndex((t) => t.label === value.label && t.id === value.id)
    );

    // Set focus
    this.$nextTick(() => {
      this.$refs.dropdownBox?.focus();
    });
  },
  methods: {
    handleArrowDown() {
      if (this.highlightedIndex < this.filteredOptions.length - 1) {
        this.highlightedIndex++;
      }
    },
    handleArrowUp() {
      if (this.highlightedIndex > 0) {
        this.highlightedIndex--;
      }
    },
    emitSelected() {
      const highlightedOption = this.filteredOptions[this.highlightedIndex];
      if (highlightedOption) {
        this.selectOption(highlightedOption);
      }
    },
    selectOption(option) {
      if (!this.multiSelect) {
        this.selectedOptions = [option];
      } else {
        const index = this.selectedOptions.findIndex(
          (selected) => selected.id === option.id
        );
        if (index === -1) {
          this.selectedOptions.push(option);
        } else {
          this.selectedOptions.splice(index, 1);
        }
      }
      this.$emit("selected", this.selectedOptions);
    },
    searchOption() {
      const searchTerm = this.searchText.toLowerCase();
      this.filteredOptions = this.dropdownOptions.filter((item) =>
        (item.label || item).toLowerCase().includes(searchTerm)
      );
      this.highlightedIndex = 0; // Reset highlight
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.dropdown_box {
  position: relative;
  box-shadow: 5px 5px 15px $shadow-color-lg;
  z-index: 2;
  outline: none;
}
.dropdown_input {
  border-radius: 10px 10px 0 0;
  position: absolute;
}
.dropdown {
  background-color: $secondary-bg-color;
  box-shadow: 0px 10px 20px $shadow-color-lg;
  padding: 0;
  width: 100%;
  position: absolute;
  top: 0px;
  border-radius: 0 0 10px 10px;
  max-height: 30vh;
  overflow-y: auto;

  &.--search_box {
    top: 40px;
  }

  .dropdown_item {
    padding: $sp-md $sp-lg;
    border-bottom: 1px solid $tertiary-text-color;
    font-size: $txt-xs;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      cursor: pointer;
      background-color: $secondary-bg-color-hover;
    }
    &.--highlighted {
      cursor: pointer;
      background-color: $secondary-bg-color-hover;
    }

    &.--selected {
      background-color: $secondary-bg-color-hover;
    }
  }
}
</style>
